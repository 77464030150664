export enum ResultVariantEnum {
  AUTO_IDEAL,
  SIZE_UNAVAILABLE,
  CLASSIC_IDEAL,
  DEFAULT
}

export interface ResultVariant {
  color: string;
  title: string;
  variant: ResultVariantEnum;
  text: string;
}
