import React, { FC, useState } from 'react';
import QRCode from 'react-qr-code';
import Lottie from 'lottie-react';
import { useTranslation } from 'react-i18next';

import { ArrowForwardIos } from '@mui/icons-material';
import GppGoodIcon from '@mui/icons-material/GppGood';

import './desktop.scss';

interface IPropsQrcodeDesktop {
  showSkip?: boolean;
  skipScan?: () => void;
  uid?: string;
  mid?: string;
  gender?: string;
  pid?: string;
  url?: string;
  loading?: boolean;
  header: React.ReactNode;
}

const QrcodeDesktop: FC<IPropsQrcodeDesktop> = ({
  showSkip,
  skipScan,
  gender,
  uid,
  mid,
  pid,
  url,
  loading,
  header
}) => {
  const { t } = useTranslation('components/qrcode/desktop');
  const [inProgress, setInProgress] = useState(false);

  return (
    <div className="qrcode_desktop">
      {header}
      <div className="qrcode_desktop__video">
        <video muted playsInline autoPlay loop>
          <source
            src={
              gender === 'male'
                ? require('../../../../assets/qrcode/demo-male.mp4')
                : require('../../../../assets/qrcode/demo-female.mp4')
            }
            type="video/mp4"
          />
        </video>
        <div className="qrcode_desktop__video__safety">
          {<GppGoodIcon sx={{ fontSize: '20px', marginRight: '5px' }} />}
          {t('safety')}
        </div>
        {false && (
          <button
            className="qrcode_desktop__video__help"
            //onClick={() => setHelpDialog(true)}
          >
            <img
              className="qrcode_desktop__video__help__icon"
              src={require('../../../../assets/help.png')}
            />
            {t('help.title')}
          </button>
        )}
        <div className="qrcode_desktop__video__copyright">
          <p>Measured by</p>
          <img
            style={{
              width: '32px',
              marginLeft: '3px',
              marginBottom: '4px',
              height: 'auto'
            }}
            src={require('../../../../assets/logo/kleep.png')}
          />
        </div>
      </div>
      <div className="qrcode_desktop__body">
        <div className="qrcode_desktop__body__section">
          <div className="qrcode_desktop__body__section__text">
            <p className="qrcode_desktop__body__section__text__title">
              {inProgress === false
                ? t('scan.title')
                : t('banner.title.restart')}
            </p>
            <p className="qrcode_desktop__body__section__text__description">
              {t('scan.description')}
            </p>
          </div>
          {!loading ? (
            <QRCode
              className="qrcode_desktop__body__section__qrcode"
              size={120}
              value={`${url}`}
              viewBox={'0 0 256 256'}
            />
          ) : (
            <Lottie
              className="qrcode_desktop__body__section__qrcode_animation"
              animationData={require('../../../../assets/animations/qrcode_loader.json')}
            />
          )}
        </div>
        <div className="qrcode_desktop__body__separator">{t('separator')}</div>
        <div className="qrcode_desktop__body__section" onClick={skipScan}>
          <div className="qrcode_desktop__body__section__text">
            <p className="qrcode_desktop__body__section__text__title">
              {inProgress === false
                ? t('questionnaire.title')
                : t('banner.title.restart')}
            </p>
            <p className="qrcode_desktop__body__section__text__description">
              {t('questionnaire.description')}
            </p>
          </div>
          <ArrowForwardIos className="qrcode_desktop__body__section__arrow" />
        </div>
      </div>
    </div>
  );
};

export default QrcodeDesktop;
