import React, { FC, useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { getStocks, recommend, recommendHistory } from '../../../api/endpoints';

import './loading.scss';
import { useMediaQuery } from 'react-responsive';
import { ResultVariant, ResultVariantEnum } from '../../../Types/Result';
import {
  CheckRecommendationData,
  CheckRecommendationHistoryData,
  CheckRecommendationItem
} from '../../../Types/Recommendations';
import { StockData } from '../../../Types/Stock';

interface IPropsResultLoading {
  nextStep: (value: boolean) => void;
  sendAnswers: () => any;
  setRecommendedSize: (value: any) => void;
  header?: React.ReactNode;
  setResultVariant(value: ResultVariant): void;
  gender: () => void;
  domain: string | null;
  handleNavigateToResult(): void;
}

const ResultLoading: FC<IPropsResultLoading> = ({
  nextStep,
  sendAnswers,
  setRecommendedSize,
  header,
  gender,
  setResultVariant,
  domain,
  handleNavigateToResult
}) => {
  const [value, setValue] = useState(0);
  const isMobile = useMediaQuery({ maxWidth: 1000 });
  const { t } = useTranslation('components/results/loading');

  const handleGetResult = async (pid: string, domain: string) => {
    const recommendHistoryData: CheckRecommendationHistoryData =
      await recommendHistory(pid, domain);

    if (recommendHistoryData && recommendHistoryData.data) {
      const stocks: StockData = await getStocks(domain, pid);

      if (stocks && stocks.data) {
        setResultVariant({
          title: 'Taille idéale',
          color: '#367A25',
          text: 'Nous vous recommandons la taille S pour ce produit grâce vos achats précédents et ceux de clients similaires.',
          variant: ResultVariantEnum.AUTO_IDEAL
        });
      } else {
        setResultVariant({
          title: 'Taille idéale',
          color: '#367A25',
          text: '',
          variant: ResultVariantEnum.SIZE_UNAVAILABLE
        });
      }

      handleNavigateToResult();
      return recommendHistoryData;
    }

    const recommendData: CheckRecommendationItem[] | undefined =
      await recommend(pid);

    if (recommendData && Array.isArray(recommendData)) {
      const stocks: StockData = await getStocks(domain, pid);

      if (stocks && stocks.data) {
        setResultVariant({
          title: 'Taille idéale',
          color: '#367A25',
          text: 'Des milliers de clients ayant la même morphologie que vous ont acheté la taille S de cet article et ont été satisfaits.',
          variant: ResultVariantEnum.CLASSIC_IDEAL
        });
      } else {
        setResultVariant({
          title: 'Taille idéale',
          color: '#367A25',
          text: '',
          variant: ResultVariantEnum.SIZE_UNAVAILABLE
        });
      }

      handleNavigateToResult();
      return recommendData;
    }

    gender();
  };

  useEffect(() => {
    if (value < 50) {
      const result = sendAnswers()
        .then((result: any) => result)
        .catch((e: string) => console.log('error', e));

      if (result) {
        setTimeout(() => {
          setValue((prev) => prev + 50);
        }, 400);
      }
    }

    if (value === 50) {
      const urlParams = new URLSearchParams(window.location.search);
      const pid = urlParams.get('product_id');
      if (pid && domain) {
        handleGetResult(pid, domain);
      }
      const reco = recommend(pid)
        .then((result: any) => {
          setRecommendedSize(result);
          setValue((prev) => prev + 50);
        })
        .catch((e: any) => {
          console.log('error recommend:', e);
        });
    }
  }, [value]);

  return (
    <div className="result_loading">
      {header}
      <div className="result_loading__progress">
        <div className="result_loading__progress__loader">
          <CircularProgress
            size={isMobile ? 200 : 250}
            className="result_loading__progress__loader__circle"
            variant="determinate"
            value={100}
          />
          <CircularProgress
            size={isMobile ? 200 : 250}
            className="result_loading__progress__loader__circle_loader"
            variant="determinate"
            value={value}
          />
        </div>
        <div className="result_loading__progress__label">{value}%</div>
      </div>
      <div className="result_loading__text">
        <p className="result_loading__text__description">{t('description')}</p>
        <p className="result_loading__text__tips">{t('tips')}</p>
      </div>
    </div>
  );
};

export default ResultLoading;
