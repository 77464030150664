import React, { FC, useEffect, useState } from 'react';
import { MenuItem, Select, Typography } from '@mui/material';
import EastRoundedIcon from '@mui/icons-material/EastRounded';

import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

import { i18n } from '../../../i18n';

import './stepBreasts.scss';

interface IPropsStepBreasts {
  nextStep: () => void;
  skipStep: () => void;
  value_1: {
    current: string;
  };
  value_2: {
    current: string;
  };
  header: React.ReactNode;
}

const StepBreasts: FC<IPropsStepBreasts> = ({
  nextStep,
  skipStep,
  value_1,
  value_2,
  header
}) => {
  const isMobile = useMediaQuery({ maxWidth: 1000 });
  const { t } = useTranslation('components/breasts');
  const [sizeCountry, setSizeCountry] = useState<string>(
    i18n.language.split('-')[0]
  );
  const SIZES_TRANSLATOR = [
    ['75', '80', '85', '90', '95', '100'],
    ['105', '110', '115', '120', '125', '130']
  ];
  const CUPS_TRANSLATOR = [
    ['A', 'B', 'C', 'D', 'E', 'F'],
    ['G', 'H', 'I', 'J', 'K', 'L']
  ];
  const SIZES = [
    {
      country: 'fr',
      value: [
        ['75', '80', '85', '90', '95', '100'],
        ['105', '110', '115', '120', '125', '130']
      ]
    },
    {
      country: 'en',
      value: [
        ['28', '30', '32', '34', '36', '38'],
        ['40', '42', '44', '46', '48', '50']
      ]
    },
    {
      country: 'de',
      value: [
        ['55', '65', '75', '85', '95', '105'],
        ['115', '125', '135', '145', '155', '165']
      ]
    },
    {
      country: 'it',
      value: [
        ['0', '1', '2', '3', '4', '5'],
        ['6', '7', '8', '9', '10', '11']
      ]
    }
  ];
  const CUPS = [
    {
      country: 'fr',
      value: [
        ['A', 'B', 'C', 'D', 'E', 'F'],
        ['G', 'H', 'I', 'J', 'K', 'L']
      ]
    },
    {
      country: 'en',
      value: [
        ['AA', 'A', 'B', 'C', 'D', 'DD'],
        ['E', 'F', 'FF', 'G', 'H', 'I']
      ]
    },
    {
      country: 'de',
      value: [
        ['A', 'B', 'C', 'D', 'E', 'F'],
        ['G', 'H', 'I', 'J', 'K', 'L']
      ]
    },
    {
      country: 'it',
      value: [
        ['A', 'B', 'C', 'D', 'DD', 'E'],
        ['F', 'FF', 'G', 'GG', 'H', 'HH']
      ]
    }
  ];
  const [selectedSize, setSelectedSize] = useState<string | undefined>(
    value_1.current === '' ? undefined : value_1.current
  );
  const [selectedCup, setSelectedCup] = useState<string | undefined>(
    value_2.current === '' ? undefined : value_2.current
  );

  useEffect(() => {
    if (selectedSize !== undefined && selectedCup !== undefined) {
      value_1.current = selectedSize.toString();
      value_2.current = selectedCup.toString();
    }
  }, [selectedSize, selectedCup]);

  return (
    <div className="breasts">
      {header}
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: '20px',
          fontFamily: 'Roboto Flex',
          maxWidth: '290px'
        }}
      >
        Choisissez votre taille de soutien-gorge
      </Typography>
      <Select
        value={sizeCountry}
        onChange={(event) => {
          setSizeCountry(event.target.value);
        }}
        className="breasts__select"
        MenuProps={{
          MenuListProps: {
            sx: {
              padding: 0,
              '& .Mui-selected': {
                display: 'none'
              }
            }
          },
          PaperProps: {
            sx: {
              backgroundColor: '#EFEFEF',
              boxShadow: 'none !important',
              borderRadius: '0px 0px 5px 5px !important',
              transform: 'translateY(-5px) !important'
            }
          }
        }}
      >
        <MenuItem className="breasts__select__item" value="fr">
          <p className="breasts__select__item__text">{`${t('select')}:`}</p>
          <span className="breasts__select__item__flag-icons FR"></span>
        </MenuItem>
        <MenuItem className="breasts__select__item" value="en">
          <p className="breasts__select__item__text">{`${t('select')}:`}</p>
          <span className="breasts__select__item__flag-icons GB"></span>
        </MenuItem>
        <MenuItem className="breasts__select__item" value="de">
          <p className="breasts__select__item__text">{`${t('select')}:`}</p>
          <span className="breasts__select__item__flag-icons DE"></span>
        </MenuItem>
        {/* <MenuItem className="breasts__select__item" value="it">
          <p className="breasts__select__item__text">{`${t('select')}:`}</p>
          <span className="breasts__select__item__flag-icons IT"></span>
        </MenuItem> */}
      </Select>
      {/* {isMobile === false ? (
        <div className="breasts__titles">
          <p className="breasts__titles__title">{t('size')}</p>
        </div>
      ) : null} */}
      <div className="breasts__body">
        <div>
          <p className="breasts__body__title">{t('size')}</p>
          <table className="breasts__body__table">
            <tbody>
              {SIZES.find((value) => value.country === sizeCountry)?.value.map(
                (row, rowIndex) => (
                  <tr key={row[0]}>
                    {row.map((cellId, colIndex) => (
                      <td key={cellId}>
                        <button
                          className={`breasts__body__table__cell ${
                            value_1.current ===
                              SIZES_TRANSLATOR[rowIndex][colIndex] ||
                            selectedSize ===
                              SIZES_TRANSLATOR[rowIndex][colIndex]
                              ? 'breasts__body__table__cell--selected'
                              : undefined
                          }`}
                          onClick={() => {
                            value_1.current =
                              SIZES_TRANSLATOR[rowIndex][colIndex];
                            setSelectedSize(
                              SIZES_TRANSLATOR[rowIndex][colIndex]
                            );
                          }}
                        >
                          {cellId}
                        </button>
                      </td>
                    ))}
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
        <div>
          <p className="breasts__body__title">{t('cup')}</p>
          <table className="breasts__body__table">
            <tbody>
              {CUPS.find((value) => value.country === sizeCountry)?.value.map(
                (row, rowIndex) => (
                  <tr key={row[0]}>
                    {row.map((cellId, colIndex) => (
                      <td key={cellId} className="breasts__body__table__test">
                        <button
                          className={`breasts__body__table__cell ${
                            value_2.current ===
                              CUPS_TRANSLATOR[rowIndex][colIndex] ||
                            selectedSize === CUPS_TRANSLATOR[rowIndex][colIndex]
                              ? 'breasts__body__table__cell--selected'
                              : undefined
                          }`}
                          onClick={() => {
                            value_2.current =
                              CUPS_TRANSLATOR[rowIndex][colIndex];
                            setSelectedCup(CUPS_TRANSLATOR[rowIndex][colIndex]);
                          }}
                        >
                          {cellId}
                        </button>
                      </td>
                    ))}
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="breasts__buttons">
        <button
          disabled={selectedSize === undefined || selectedCup === undefined}
          className="breasts__buttons__continue"
          onClick={() => nextStep()}
        >
          {t('buttons.continue')}
        </button>
        <button className="breasts__buttons__skip" onClick={() => skipStep()}>
          {t('buttons.skip')}
        </button>
      </div>
    </div>
  );
};

export default StepBreasts;
