import React, {
  FC,
  useCallback,
  useDebugValue,
  useEffect,
  useState
} from 'react';
import { Trans, useTranslation } from 'react-i18next';

import './newResultsDesign.scss';
import { useAppContext } from '../../../context/AppContext';

import { useMediaQuery } from 'react-responsive';

import bg from '../../../assets/result/bg.png';
import monogram from '../../../assets/mongram.png';
import CloseIcon from '../../../icons/CloseIcon';
import { IconButton } from '@mui/material';
import { RecommendationsSlider } from '../../RecommendationsSlider';
import recommendationsImage from '../../../assets/recommendations/img1.png';
import { RecommendationsData } from '../../../Types/Recommendations';
import { ResultVariant, ResultVariantEnum } from '../../../Types/Result';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  SimilarProductItem,
  SimilarProductsData
} from '../../../Types/SimilarProducts';
import { similarProducts } from '../../../api/endpoints';
import { QueryParams } from '../../../Types/common';
import ResultAutoIdeal from './ResultAutoIdeal';

interface IPropsResult {
  nextStep: () => void;
  restart: () => void;
  reducedResult?: any;
  focused: number;
  setFocused: (key: number) => void;
  product: any;
  productFeedback: any;
  fitFeedback: any;
  colors: any;
  feedbacks: any;
  header: React.ReactNode;
  resultVariant: ResultVariant | undefined;
  bgImageUrl?: string | null;
  domain: string | null;
}

const closeIframe = () => {
  window.parent.postMessage(
    { data: 'mid', mid: localStorage.getItem('mid') },
    '*'
  );
  window.parent.postMessage({ action: 'closeIframe' }, '*');
};
const sendMid = () =>
  window.parent.postMessage(
    { data: 'mid', mid: localStorage.getItem('mid') },
    '*'
  );

const NewResultsDesign: FC<IPropsResult> = ({
  nextStep,
  restart,
  reducedResult,
  focused,
  setFocused,
  product,
  productFeedback,
  fitFeedback,
  feedbacks,
  colors,
  header,
  resultVariant,
  bgImageUrl,
  domain
}) => {
  if (!reducedResult) {
    return null;
  }

  const getQueryParams = useCallback((): QueryParams => {
    const searchParams = new URLSearchParams(window.location.search);
    const params: QueryParams = {};
    for (const param of searchParams.entries()) {
      params[param[0]] = param[1];
    }
    return params;
  }, [location.search]);

  const { t } = useTranslation('components/results/result');
  const isMobile = useMediaQuery({ maxWidth: 1000 });
  const [similarProductsData, setSimilarProductsData] = useState<
    SimilarProductItem[] | undefined
  >(undefined);

  const initCentersAndDeltas = (isMobile: boolean) => {
    return {
      centers: {
        middle: 58,
        left: isMobile ? 65 : 62,
        right: isMobile ? 50 : 54
      },
      deltas: {
        middle: 0,
        left: isMobile ? -14 : -7,
        right: isMobile ? 14 : 7
      }
    };
  };

  const [margins, setMargins] = useState(initCentersAndDeltas(isMobile));

  useEffect(() => {
    sendMid();
  }, []);

  const cuts_template = ['Fitted', 'Regular', 'Oversize'];

  const initCenter = () => {
    const possibleResults = Object.keys(reducedResult).reduce(
      (acc: any, key) => {
        if (reducedResult[key].possible) {
          acc[key] = reducedResult[key];
        }
        return acc;
      },
      {}
    );
    const sum = Object.keys(possibleResults).reduce(
      (acc: any, current: any) => acc + Number(current),
      0
    );

    const average = sum / Object.keys(possibleResults).length;
    if (average === 1.5) return margins.centers.right;
    if (average === 0.5) return margins.centers.left;
    else return margins.centers.middle;
  };

  const initDelta = () => {
    if (focused === 2) return margins.deltas.left;
    if (focused === 0) return margins.deltas.right;
    else return margins.deltas.middle;
  };

  const handleResize = () => {
    initCenter();
    initDelta();
    setMargins(initCentersAndDeltas(isMobile));
  };

  const [initMargin, setInitMargin] = useState(initCenter());
  const [deltaMargin, setDeltaMargin] = useState(initDelta());

  useEffect(() => {
    console.log('reducedResult', reducedResult);
  }, [reducedResult]);

  useEffect(() => {
    setInitMargin(initCenter());
  }, [reducedResult]);

  useEffect(() => {
    setDeltaMargin(initDelta());
  }, [focused]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const appContext: any = useAppContext() || '';
  const product_id = appContext.pid;
  useEffect(() => {
    const variant_id = getQueryParams().variant_id;

    async function fetchSimilarProducts() {
      const res: SimilarProductsData = await similarProducts(
        product_id,
        domain,
        variant_id
      );

      if (res) {
        setSimilarProductsData(res);
      } else {
        setSimilarProductsData(undefined);
      }
    }

    if (resultVariant?.variant === ResultVariantEnum.SIZE_UNAVAILABLE) {
      fetchSimilarProducts();
    }
  }, [resultVariant]);

  const Fits = ({ style }: any) => {
    return (
      <div style={{ ...style }}>
        <div
          style={{
            width: '100%',
            height: '100%',
            pointerEvents: 'none',
            backgroundImage: `url(${bgImageUrl})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        />
      </div>
    );
  };

  return (
    <div className="result">
      {!isMobile && (
        <Fits
          style={{
            height: '100%',
            width: '40%',
            display: isMobile ? 'none' : 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#FBFBFB'
          }}
        />
      )}
      <div
        style={{
          width: isMobile ? '100%' : '60%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '14px 10px 0px 10px'
        }}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <div>
              <img src={monogram} alt="logo" />
            </div>
            <div>
              <p className="result__main-title">{t('size')}</p>
            </div>
            <div>
              <IconButton>
                <CloseIcon
                  sx={{ color: '#000', width: '11px', height: 'auto' }}
                />
              </IconButton>
            </div>
          </div>
          <div className="result__size">
            {[0, 1, 2].map((val, idx) => {
              if (Object.keys(reducedResult).includes(idx.toString())) {
                return (
                  <div
                    key={idx}
                    className={`result__size__label result__size__label--${
                      focused === idx ? 'focused' : 'notfocused'
                    } result__size__label--${
                      idx === 1 ? 'centered' : idx === 0 ? 'started' : 'ended'
                    }`}
                    style={{
                      display: focused !== idx ? 'none' : 'block',
                      color:
                        resultVariant?.variant ===
                        ResultVariantEnum.SIZE_UNAVAILABLE
                          ? '#C0C0C0'
                          : '#000'
                    }}
                  >
                    {reducedResult[idx].label}
                    {resultVariant?.variant ===
                      ResultVariantEnum.SIZE_UNAVAILABLE && (
                      <div className="result__size__indisponible">
                        Indisponible
                      </div>
                    )}
                  </div>
                );
              } else {
                return (
                  <div
                    key={idx}
                    onClick={() => setFocused(idx)}
                    className={`result__size__label result__size__label--notfocused result__size__label--${
                      idx === 1 ? 'centered' : idx === 0 ? 'started' : 'ended'
                    }`}
                    style={{ display: 'none' }}
                  />
                );
              }
            })}
          </div>
          <div
            style={{
              textAlign: 'center',
              fontWeight: 700,
              color: resultVariant?.color || '#000',
              fontSize: '14px',
              transform: 'translateY(-25px)'
            }}
          >
            {resultVariant?.title || ''}{' '}
            <CheckCircleIcon sx={{ width: '16px', height: '16px' }} />
          </div>
          <div className="result__preference">
            {cuts_template.map((cut, idx) => (
              <div
                key={idx}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <div
                  onClick={() => {
                    if (Object.keys(reducedResult).includes(idx.toString()))
                      setFocused(idx);
                  }}
                  className={`result__preference__choice result__preference__choice--${
                    focused === idx ? 'focused' : 'notfocused'
                  } result__preference__choice--${
                    idx === 1 ? 'centered' : idx === 0 ? 'started' : 'ended'
                  }`}
                >
                  <div
                    className="result__preference__choice__box"
                    style={{
                      cursor:
                        Object.keys(reducedResult).includes(idx.toString()) &&
                        focused !== idx
                          ? 'pointer'
                          : undefined,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                      fontSize: '12px',
                      fontWeight:
                        Object.keys(reducedResult).includes(idx.toString()) &&
                        focused === idx
                          ? 700
                          : 400,
                      fontFamily: 'Roboto Flex',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      color:
                        Object.keys(reducedResult).includes(idx.toString()) &&
                        focused === idx
                          ? '#000'
                          : '#C0C0C0',
                      width: '97px',
                      height: '29px',
                      border: '1px solid',
                      borderColor:
                        Object.keys(reducedResult).includes(idx.toString()) &&
                        focused === idx
                          ? resultVariant?.color || '#000'
                          : '#C0C0C0',
                      borderRadius:
                        (idx === 0 && '8px 0px 0px 8px') ||
                        (idx === 1 && '0px') ||
                        (idx === 2 && '0px 8px 8px 0') ||
                        '0px'
                    }}
                  >
                    {t(`comments.${product_id}.cuts.${cut}.name`)}
                  </div>
                </div>
              </div>
            ))}
          </div>
          {resultVariant?.variant === ResultVariantEnum.SIZE_UNAVAILABLE &&
            similarProductsData && (
              <>
                <p className="result__preference__recommendations__title">
                  Articles similaires disponibles dans cette taille :
                </p>
                <RecommendationsSlider data={similarProductsData} />
              </>
            )}
          {resultVariant?.variant !== ResultVariantEnum.SIZE_UNAVAILABLE && (
            <div className="result__advice">
              <div className="result__advice__cut">
                <div className="result__advice__cut__content">
                  {resultVariant?.text || t('customersMajority')}
                </div>
              </div>
            </div>
          )}
          <div
            className="result__buttons"
            style={{
              width: '100%'
            }}
          >
            {resultVariant?.variant !== ResultVariantEnum.SIZE_UNAVAILABLE && (
              <button
                onClick={() => {
                  window.parent.postMessage(
                    {
                      action: 'addToCart',
                      variantId: reducedResult[focused].variant_id
                    },
                    '*'
                  );
                  sendMid();
                  closeIframe();
                }}
                className="result__buttons__save"
              >
                <Trans
                  ns="components/results/result"
                  i18nKey="cart"
                  values={{ size: reducedResult[focused]?.label || '' }}
                />
              </button>
            )}
            <div className="result__buttons__text">
              {false && (
                <button
                  onClick={() => nextStep()}
                  className="result__buttons__text__products"
                >
                  {t('products')}
                </button>
              )}
              {resultVariant?.variant === ResultVariantEnum.AUTO_IDEAL ? (
                <ResultAutoIdeal />
              ) : (
                <button
                  className="result__buttons__text__restart"
                  onClick={restart}
                >
                  {t('restart')}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewResultsDesign;
