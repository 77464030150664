import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './index.scss';
import FeetInputs from '../FeetInputs';
import { useMediaQuery } from 'react-responsive';

interface Props {
  object?: any;
  setDisabled: (e: object) => void;
}

const InputWithRadioButtons: React.FC<Props> = ({ setDisabled, object }) => {
  const [error, setError] = useState<boolean>(false);
  const [value, setValue] = useState<number | undefined>(
    object?.inputRef?.current
  );

  const { t } = useTranslation('components/questions');
  const isMobile = useMediaQuery({ maxWidth: 1000 });
  const unit = object.unit;

  useEffect(() => {
    setDisabled((prev: object) => ({
      ...prev,
      [object.name]: error || !value
    }));
  }, [error, value, object.unit]);

  // todo - show error only if user removed focus from textfield

  const getColor = (item: string) => {
    if (isMobile) {
      if (unit === item) {
        return '#fff';
      } else {
        return '#565656';
      }
    }

    if (unit === item) {
      return '#000';
    } else {
      return '#9F9F9F';
    }
  };

  return object.name === 'height' && object.unit === 'feet' ? (
    <FeetInputs
      object={object}
      value={value}
      error={error}
      setValue={setValue}
      setError={setError}
    />
  ) : (
    <div className="textfield">
      <div className="textfield__title">{t(`${object.name}.title`)}</div>
      <div className="textfield__input">
        <TextField
          value={
            value === undefined
              ? ''
              : unit === 'feet'
              ? `${value?.toString().substring(0, 1)}${
                  value?.toString().slice(1) === '' ? "'‎" : "'"
                }${value?.toString().slice(1)}${
                  value?.toString().slice(1) === '' ? '' : '‎'
                }`
              : value
          }
          required={true}
          placeholder={t(`${object.name}.placeholder`)}
          inputProps={{
            min: object.min,
            max: object.max,
            style: {
              textAlign: 'start',
              marginTop: '5px'
            }
          }}
          InputProps={{
            inputProps: {
              style: {
                textAlign: 'left',
                fontSize: isMobile ? '12px' : '14px'
              }
            }
          }}
          sx={{
            width: '100%',
            '& .MuiInput-underline:after': {
              borderBottomColor: error ? 'red' : 'black'
            },
            '& .MuiInput-underline:before': {
              borderBottomColor: error ? 'red' : 'black'
            },
            '&:hover .MuiInput-underline:before': {
              borderBottomColor: error ? 'red' : 'black'
            },
            '& .MuiInputBase-root': {
              borderRadius: 0,
              padding: isMobile ? '6.5px 10px 6.5px 10px' : 0,
              width: '100%'
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#000'
            },
            '& .Mui-focused fieldset': {
              borderColor: '#000!important'
            }
          }}
          onChange={(event) => {
            if (unit === 'feet') {
              const tmpValue =
                event.target.value.match('["\']') !== null &&
                event.target.value.search('‎[0-9]') === -1 &&
                event.target.value.search(
                  event.target.value.slice(1) === '' ? '‎' : '‎'
                ) === -1
                  ? event.target.value.replace(/\D/g, '').slice(0, -1)
                  : parseInt(event.target.value.replace(/\D/g, '').slice(1)) >
                    12
                  ? event.target.value.replace(/\D/g, '').slice(0, -1)
                  : event.target.value.replace(/\D/g, '');
              const newValue = parseInt(tmpValue, 10) || undefined;

              setValue(newValue);

              if (newValue !== undefined) {
                setError(newValue < object.min || newValue > object.max);
              }
              object.inputRef.current = newValue;
            } else {
              const tmpValue =
                event.target.value.match('[a-zA-Z]') !== null &&
                event.target.value.search(unit || '') === -1
                  ? event.target.value.replace(/\D/g, '').slice(0, -1)
                  : event.target.value.replace(/\D/g, '');
              const newValue = parseInt(tmpValue, 10) || undefined;

              setValue(newValue);

              if (newValue !== undefined) {
                setError(newValue < object.min || newValue > object.max);
              }
              object.inputRef.current = newValue;
            }
          }}
          variant={isMobile ? 'outlined' : 'standard'}
        />
        <div className="input-width-radio-root">
          {object.unitVariants.map((item: string) => (
            <div
              key={item}
              onClick={() => object.onUnitVariantChange(item)}
              className={
                unit === item
                  ? 'input-width-radio-root__selected-item'
                  : 'input-width-radio-root__item'
              }
            >
              <p
                style={{
                  color: getColor(item),
                  cursor: 'pointer',
                  fontSize: isMobile ? '8px' : '14px'
                }}
              >
                {item}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div
        className="textfield__error"
        style={{
          opacity: error ? 1 : 0
        }}
      >
        {t(`${object.name}.error${unit}`)}
      </div>
    </div>
  );
};

export default InputWithRadioButtons;
