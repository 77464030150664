import React, { FC, useState } from 'react';

import { useMediaQuery } from 'react-responsive';
import { GENDERS } from '../../../utils';
import { useTranslation } from 'react-i18next';

import belly_1_female from '../../../assets/questionnaire/belly/belly-1-female.svg';
import belly_2_female from '../../../assets/questionnaire/belly/belly-2-female.svg';
import belly_3_female from '../../../assets/questionnaire/belly/belly-3-female.svg';
import belly_1_male from '../../../assets/questionnaire/belly/belly-1-male.svg';
import belly_2_male from '../../../assets/questionnaire/belly/belly-2-male.svg';
import belly_3_male from '../../../assets/questionnaire/belly/belly-3-male.svg';

import './stepBelly.scss';
import ImageChoiceWrapper from '../../ImageChoiceWrapper';
import { ImageChoiceData } from '../../../Types/ImageChoice';

interface IPropsStepBelly {
  gender?: string;
  nextStep: () => void;
  skip: () => void;
  value: {
    current: number;
  };
  header: React.ReactNode;
  isShowScanButton: boolean;
}

const StepBelly: FC<IPropsStepBelly> = ({
  gender,
  nextStep,
  skip,
  value,
  header,
  isShowScanButton
}) => {
  const isMobile = useMediaQuery({ maxWidth: 1000 });
  const { t } = useTranslation('components/belly');
  const [selected, setSelected] = useState<number | undefined>(value.current);

  const data: ImageChoiceData = [
    {
      image: gender === GENDERS.F ? belly_1_female : belly_1_male,
      onClick: () => {
        value.current = 1;
        setSelected(1);
      },
      text: t('size.one'),
      isSelected: selected === 1
    },
    {
      image: gender === GENDERS.F ? belly_2_female : belly_2_male,
      onClick: () => {
        value.current = 2;
        setSelected(2);
      },
      isSelected: selected === 2,
      text: t('size.two')
    },
    {
      image: gender === GENDERS.F ? belly_3_female : belly_3_male,
      onClick: () => {
        value.current = 3;
        setSelected(3);
      },
      text: t('size.three'),
      isSelected: selected === 3
    }
  ];

  return (
    <div className="belly">
      {header}
      <ImageChoiceWrapper
        data={data}
        description={t('description')}
        title={t('title')}
        subtitle={t('subtitle')}
        gender={gender}
        nextStep={nextStep}
        selected={selected}
        skip={skip}
        currentStep={1}
        isShowScanButton={isShowScanButton}
      />
    </div>
  );
};

export default StepBelly;
