import React, { FC, useEffect } from 'react';
import Lottie from 'lottie-react';

import './wait.scss';
import {
  getConfig,
  getStocks,
  recommend,
  recommendHistory
} from '../../../api/endpoints';
import { ResultVariant, ResultVariantEnum } from '../../../Types/Result';
import {
  CheckRecommendationHistoryData,
  CheckRecommendationItem
} from '../../../Types/Recommendations';
import { StockData } from '../../../Types/Stock';

interface IPropsWait {
  domain?: string | null;
  setConfig: (config: {
    images: {
      male: string | null;
      female: string | null;
      default: string | null;
    } | null;
    font: string | null;
  }) => void;
  intro: () => void;
  result: (r: any) => void;
  setModalConfigData(value: object): void;
  setResultVariant(value: ResultVariant): void;
  gender: () => void;
  handleNavigateToResult(): void;
}

const Wait: FC<IPropsWait> = ({
  domain,
  setConfig,
  intro,
  result,
  setModalConfigData,
  setResultVariant,
  gender,
  handleNavigateToResult
}) => {
  const handleGetResult = async (pid: string, domain: string) => {
    const recommendHistoryData: CheckRecommendationHistoryData =
      await recommendHistory(pid, domain);

    if (recommendHistoryData && recommendHistoryData.data) {
      const stocks: StockData = await getStocks(domain, pid);

      if (stocks && stocks.data) {
        setResultVariant({
          title: 'Taille idéale',
          color: '#367A25',
          text: 'Nous vous recommandons la taille S pour ce produit grâce vos achats précédents et ceux de clients similaires.',
          variant: ResultVariantEnum.AUTO_IDEAL
        });
      } else {
        setResultVariant({
          title: 'Taille idéale',
          color: '#367A25',
          text: '',
          variant: ResultVariantEnum.SIZE_UNAVAILABLE
        });
      }

      handleNavigateToResult();
      return recommendHistoryData;
    }

    const recommendData: CheckRecommendationItem[] | undefined =
      await recommend(pid);

    if (recommendData && Array.isArray(recommendData)) {
      const stocks: StockData = await getStocks(domain, pid);

      if (stocks && stocks.data) {
        setResultVariant({
          title: 'Taille idéale',
          color: '#367A25',
          text: 'Des milliers de clients ayant la même morphologie que vous ont acheté la taille S de cet article et ont été satisfaits.',
          variant: ResultVariantEnum.CLASSIC_IDEAL
        });
      } else {
        setResultVariant({
          title: 'Taille idéale',
          color: '#367A25',
          text: '',
          variant: ResultVariantEnum.SIZE_UNAVAILABLE
        });
      }

      handleNavigateToResult();
      return recommendData;
    }

    gender();
  };

  useEffect(() => {
    if (
      domain !== null &&
      domain !== undefined &&
      localStorage.getItem('mid')
    ) {
      const urlParams = new URLSearchParams(window.location.search);
      const pid = urlParams.get('product_id');
      getConfig(domain).then((res) => {
        if (res.modal_config) {
          setModalConfigData({
            isShowScanButton: res.modal_config.flow.scan,
            isShowQAButton: res.modal_config.flow.qa
          });
        }

        setConfig({
          images: {
            male: res.image_url ?? null,
            female: res.image_url ?? null,
            default: res.image_url ?? null
          },
          font: res.font ?? null
        });
        handleGetResult(pid as string, domain)
          .then((r: any) => {
            if (r) {
              result(r);
              handleNavigateToResult();
            } else {
              intro();
            }
          })
          .catch((e: any) => console.log(e));
      });
    } else if (localStorage.getItem('mid')) {
      const urlParams = new URLSearchParams(window.location.search);
      const pid = urlParams.get('product_id');

      setConfig({ images: null, font: null });
      recommend(pid)
        .then((r: any) => {
          if (r) {
            result(r);
          } else {
            intro();
          }
        })
        .catch((e: any) => console.log(e));
    } else if (domain !== null && domain !== undefined) {
      getConfig(domain).then((res) => {
        setConfig({
          images: {
            male: res.image_url ?? null,
            female: res.image_url ?? null,
            default: res.image_url ?? null
          },
          font: res.font ?? null
        });
        intro();
      });
    } else {
      intro();
    }
  }, []);

  return (
    <div className="wait">
      <Lottie
        animationData={require('../../../assets/animations/loading_black.json')}
        style={{
          width: '200px',
          height: 'auto'
        }}
      />
    </div>
  );
};

export default Wait;
