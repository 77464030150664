import React from 'react';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './index.scss';
import { useMediaQuery } from 'react-responsive';

interface Props {
  object: any;
  error?: boolean;
  value?: number;
  setValue(value: number | undefined): void;
  setError(value: boolean): void;
}

const FeetInputs: React.FC<Props> = ({
  object,
  setError,
  setValue,
  error,
  value
}: Props) => {
  const unit = object.unit;
  const { t } = useTranslation('components/questions');
  const isMobile = useMediaQuery({ maxWidth: 1000 });

  const handleFocusOnInput = (inputId: string) => {
    const input = document.getElementById(inputId);

    if (input) {
      input.focus();
    }
  };

  const getColor = (item: string) => {
    if (isMobile) {
      if (unit === item) {
        return '#fff';
      } else {
        return '#565656';
      }
    }

    if (unit === item) {
      return '#000';
    } else {
      return '#9F9F9F';
    }
  };

  return (
    <div className="textfield">
      <div className="textfield__title">{t(`${object.name}.title`)}</div>
      <div
        className="textfield__input"
        style={{ display: 'flex', columnGap: isMobile ? '22px' : '30px' }}
      >
        <TextField
          id="feet-first-input"
          value={
            value === undefined ? '' : `${value.toString().substring(0, 1)}’`
          }
          required={true}
          placeholder="ex: 5’"
          inputProps={{
            min: object.min,
            max: object.max,
            style: {
              borderColor: error ? 'red' : 'black',
              textAlign: 'start',
              marginTop: '5px'
            }
          }}
          InputProps={{
            inputProps: {
              style: {
                textAlign: 'left',
                fontSize: isMobile ? '12px' : '14px'
              }
            }
          }}
          sx={{
            width: isMobile ? '100%' : '60%',
            '& .MuiInput-underline:after': {
              borderBottomColor: error ? 'red' : 'black'
            },
            '& .MuiInput-underline:before': {
              borderBottomColor: error ? 'red' : 'black'
            },
            '&:hover .MuiInput-underline:before': {
              borderBottomColor: error ? 'red' : 'black'
            },
            '& .MuiInputBase-root': {
              borderRadius: 0,
              padding: isMobile ? '6.5px 10px 6.5px 10px' : 0,
              width: '100%'
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#000'
            },
            '& .Mui-focused fieldset': {
              borderColor: '#000!important'
            }
          }}
          onChange={(event) => {
            const eventValue = event.target.value;

            if (eventValue === value?.toString()[0]) {
              setValue(undefined);
              object.inputRef.current = undefined;
              return;
            }

            const newValue = value
              ? `${eventValue}${value.toString()[1]}`
              : eventValue;

            setValue(parseFloat(newValue));

            if (newValue !== undefined) {
              setError(newValue < object.min || newValue > object.max);
            }
            object.inputRef.current = newValue;
            handleFocusOnInput('feet-second-input');
          }}
          variant={isMobile ? 'outlined' : 'standard'}
        />
        <TextField
          id="feet-second-input"
          value={
            value === undefined || value.toString().length < 2
              ? ''
              : `${value.toString()[1]}”`
          }
          required={true}
          placeholder="ex: 9”"
          inputProps={{
            min: object.min,
            max: object.max,
            style: {
              borderColor: error ? 'red' : 'black',
              textAlign: 'start',
              marginTop: '5px'
            }
          }}
          InputProps={{
            inputProps: {
              style: {
                textAlign: 'left',
                fontSize: isMobile ? '12px' : '14px'
              }
            }
          }}
          sx={{
            width: '100%',
            '& .MuiInput-underline:after': {
              borderBottomColor: error ? 'red' : 'black'
            },
            '& .MuiInput-underline:before': {
              borderBottomColor: error ? 'red' : 'black'
            },
            '&:hover .MuiInput-underline:before': {
              borderBottomColor: error ? 'red' : 'black'
            },
            '& .MuiInputBase-root': {
              borderRadius: 0,
              padding: isMobile ? '6.5px 10px 6.5px 10px' : 0,
              width: '100%'
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#000'
            },
            '& .Mui-focused fieldset': {
              borderColor: '#000!important'
            }
          }}
          onChange={(event) => {
            const eventValue = event.target.value;

            if (eventValue === value?.toString()[1]) {
              setValue(parseFloat(value.toString()[0]));
              object.inputRef.current = value.toString()[0];
              handleFocusOnInput('feet-first-input');
              return;
            }

            const newValue = value
              ? `${value.toString()[0]}${eventValue}`
              : eventValue;

            setValue(parseFloat(newValue));

            if (newValue !== undefined) {
              setError(newValue < object.min || newValue > object.max);
            }
            object.inputRef.current = newValue;
          }}
          variant={isMobile ? 'outlined' : 'standard'}
        />
        <div className="input-width-radio-root">
          {object.unitVariants.map((item: string) => (
            <div
              key={item}
              onClick={() => object.onUnitVariantChange(item)}
              className={
                unit === item
                  ? 'input-width-radio-root__selected-item'
                  : 'input-width-radio-root__item'
              }
            >
              <p
                style={{
                  color: getColor(item),
                  cursor: 'pointer',
                  fontSize: isMobile ? '8px' : '14px'
                }}
              >
                {item}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div
        className="textfield__error"
        style={{
          opacity: error ? 1 : 0
        }}
      >
        {t(`${object.name}.error${unit}`)}
      </div>
    </div>
  );
};

export default FeetInputs;
