import React, { useRef, useState } from 'react';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import './styles.scss';

import 'swiper/css';
import 'swiper/css/navigation';

import { Navigation } from 'swiper/modules';

import { RecommendationsData } from '../../Types/Recommendations';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

interface Props {
  data: RecommendationsData;
}

export const RecommendationsSlider: React.FC<Props> = ({ data }: Props) => {
  const [isSwiperLoaded, setIsSwiperLoaded] = useState(false);
  const swiperRef = useRef<SwiperRef>(null);

  return (
    <div style={{ position: 'relative' }}>
      <Swiper
        slidesPerView={3}
        className="mySwiper"
        spaceBetween={7}
        modules={[Navigation]}
        onSwiper={() => setIsSwiperLoaded(true)}
        ref={swiperRef}
        loop={true}
      >
        {data.map((item, index) => (
          <SwiperSlide key={`${item.title}-${index}`}>
            <div className="recommendations__container">
              <img
                src={item.image_url}
                alt={item.title}
                className="recommendations__container__image"
              />
              <p className="recommendations__container__name">{item.title}</p>
              <p className="recommendations__container__price">0</p>{' '}
              {/* todo - fix price */}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      {isSwiperLoaded && (
        <>
          <div onClick={() => swiperRef?.current?.swiper?.slidePrev() || ''}>
            <ArrowBackIosIcon className="arrowBack" />
          </div>
          <div onClick={() => swiperRef?.current?.swiper.slideNext()}>
            <ArrowBackIosIcon className="arrowNext" />
          </div>
        </>
      )}
    </div>
  );
};
