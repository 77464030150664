import React, { useState, useEffect, useRef, FC, Suspense } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { Box, Dialog, DialogContent, Typography } from '@mui/material';
import Lottie from 'lottie-react';
import { getProductImage, newMeasure } from '../../api/endpoints';
import '../../i18n';
import StepIntroMobile from './intro/mobile/stepIntro';
import StepIntroDesktop from './intro/desktop/stepIntro';
import StepAge from './measures/age/stepMeasure';
import StepWeight from './measures/weight/stepMeasure';
import StepQRCode from './qrcode/stepQrCode';
import StepBelly from './belly/stepBelly';
import StepTorso from './torso/stepTorso';
import StepHips from './hips/stepHips';
import StepBreasts from './breasts/stepBreasts';

import { i18n } from '../../i18n';
import Wait from './wait/wait';

import { useUserContext } from '../../store/userContext';

import {
  getLocalAndParse,
  setLocalStore,
  removeLocalStore
} from '../../store/localStoreUtils';

import Result from './results/result';
import ResultLoading from './results/loading';
import ResultError from './results/error';

import { GENDERS } from '../../utils';
import { questionPushAnswersAndTrigger } from '../../api/endpoints';
import Products from './products/products';

import homme from '../../assets/lacoste/homme.jpg';
import femme from '../../assets/lacoste/femme.jpg';
import homme_femme from '../../assets/lacoste/homme_femme.jpg';
import modal_bg from '../../assets/lacoste/modal_bg.jpg';

import './KleepModal.scss';
import StepQuestions from './questions/stepQuestions';

import H_ctwh from '../../assets/result/H_ctwh.png';
import H_ctw from '../../assets/result/H_ctw.png';
import H_h from '../../assets/result/H_h.png';
import H_twh from '../../assets/result/H_twh.png';
import F_c from '../../assets/result/F_c.png';
import F_cw from '../../assets/result/F_cw.png';
import F_wh from '../../assets/result/F_wh.png';
import ModalHeader from './header';
import NewResultsDesign from './results/newResultsDesign';
import { ResultVariant } from '../../Types/Result';
import { useAppContext } from '../../context/AppContext';

interface IPropsKleepModal {
  handleModal: (openModal: boolean, size: boolean) => void;
  isModalOpen: boolean;
  setRecommendedSize: (r: any) => void;
  productImg?: string;
  pid?: string;
  already?: boolean;
  setAlready?: (bool: boolean) => void;
}

const KleepModal: FC<IPropsKleepModal> = ({
  handleModal,
  isModalOpen,
  pid
}) => {
  const lacosteProducts: {
    [key: string]: { image: string; local: string; sizeGuide: number };
  } = {
    '8956154872090': {
      image:
        'https://image1.lacoste.com/dw/image/v2/AAQM_PRD/on/demandware.static/Sites-FR-Site/Sites-master/fr/dw1c64887c/WH2528_031_20.jpg?imwidth=915&impolicy=product',
      local: H_ctwh,
      sizeGuide: 0
    },
    '8956157296922': {
      image:
        'https://image1.lacoste.com/dw/image/v2/AAQM_PRD/on/demandware.static/Sites-FR-Site/Sites-master/fr/dw046f4961/HH2704_ML4_20.jpg?imwidth=915&impolicy=product',
      local: H_twh,
      sizeGuide: 1
    },
    '8956157985050': {
      image:
        'https://image1.lacoste.com/dw/image/v2/AAQM_PRD/on/demandware.static/Sites-FR-Site/Sites-master/fr/dw48496706/TH6712_031_20.jpg?imwidth=915&impolicy=product',
      local: H_ctw,
      sizeGuide: 0
    },
    '8956158574874': {
      image:
        'https://image1.lacoste.com/dw/image/v2/AAQM_PRD/on/demandware.static/Sites-FR-Site/Sites-master/fr/dwf0a21413/TF9246_031_20.jpg?imwidth=915&impolicy=product',
      local: F_cw,
      sizeGuide: 2
    },
    '8956159623450': {
      image:
        'https://image1.lacoste.com/dw/image/v2/AAQM_PRD/on/demandware.static/Sites-FR-Site/Sites-master/fr/dw680fa4e2/BH0538_166_20.jpg?imwidth=915&impolicy=product',
      local: H_ctw,
      sizeGuide: 0
    },
    '8956160442650': {
      image:
        'https://image1.lacoste.com/dw/image/v2/AAQM_PRD/on/demandware.static/Sites-FR-Site/Sites-master/fr/dw5247b96e/EF1031_PIM_20.jpg?imwidth=915&impolicy=product',
      local: F_cw,
      sizeGuide: 2
    },
    '8956161229082': {
      image:
        'https://image1.lacoste.com/dw/image/v2/AAQM_PRD/on/demandware.static/Sites-FR-Site/Sites-master/fr/dw7ba46e7a/L1264_SXL_20.jpg?imwidth=915&impolicy=product',
      local: H_ctw,
      sizeGuide: 0
    },
    '8956161786138': {
      image:
        'https://image1.lacoste.com/dw/image/v2/AAQM_PRD/on/demandware.static/Sites-FR-Site/Sites-master/fr/dw97c10fde/PF0504_001_20.jpg?imwidth=915&impolicy=product',
      local: F_cw,
      sizeGuide: 0
    },
    '8956162769178': {
      image:
        'https://image1.lacoste.com/dw/image/v2/AAQM_PRD/on/demandware.static/Sites-FR-Site/Sites-master/fr/dw7e27779f/CH8528_001_20.jpg?imwidth=915&impolicy=product',
      local: H_ctw,
      sizeGuide: 0
    },
    '8956163293466': {
      image:
        'https://image1.lacoste.com/dw/image/v2/AAQM_PRD/on/demandware.static/Sites-FR-Site/Sites-master/fr/dw893c9b3e/OF1561_031_20.jpg?imwidth=915&impolicy=product',
      local: F_wh,
      sizeGuide: 0
    },
    '8956164112666': {
      image:
        'https://image1.lacoste.com/dw/image/v2/AAQM_PRD/on/demandware.static/Sites-FR-Site/Sites-master/fr/dwdf0730fa/IF8167_001_20.jpg?imwidth=915&impolicy=product',
      local: F_c,
      sizeGuide: 0
    },
    '8956164833562': {
      image:
        'https://image1.lacoste.com/dw/image/v2/AAQM_PRD/on/demandware.static/Sites-FR-Site/Sites-master/fr/dw3846f7b2/7H3394_8X0_20.jpg?imwidth=915&impolicy=product',
      local: H_h,
      sizeGuide: 0
    },
    '9098393092378': {
      image:
        'https://image1.lacoste.com/dw/image/v2/AAQM_PRD/on/demandware.static/Sites-FR-Site/Sites-master/fr/dw21f7932c/BH3466_5FS_20.jpg?imwidth=915&impolicy=product',
      local: H_ctw,
      sizeGuide: 0
    }
  };

  interface FitFeedbacks {
    [key: number]: string[];
  }

  interface ProductFeedbacks {
    [key: string]: {
      chest: any;
      trouser_waist: any;
      waist: any;
      hip: any;
    };
  }

  const fit_feedbacks: FitFeedbacks = {
    0: ['Très ajusté', 'Très étroit', 'Ajusté'],
    1: ['Ajusté', 'Légèrement étroit', 'Regular'],
    2: ['Taille parfaite', 'Volontairement ample'],
    3: ['Légèrement ample', 'Ample'],
    4: ['Très ample']
  };
  const product_feedbacks: ProductFeedbacks = {
    '8956154872090': {
      chest: {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0
      },
      trouser_waist: {
        0: 0,
        1: 0,
        2: 1,
        3: 0,
        4: 0
      },
      waist: {
        0: undefined,
        1: undefined,
        2: undefined,
        3: undefined,
        4: undefined
      },
      hip: {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0
      }
    },
    '8956157296922': {
      chest: {
        0: undefined,
        1: undefined,
        2: undefined,
        3: undefined,
        4: undefined
      },
      trouser_waist: {
        0: 1,
        1: 1,
        2: 0,
        3: 0,
        4: 0
      },
      waist: {
        0: undefined,
        1: undefined,
        2: undefined,
        3: undefined,
        4: undefined
      },
      hip: {
        0: 1,
        1: 1,
        2: 0,
        3: 0,
        4: 0
      }
    },
    '8956157985050': {
      chest: {
        0: 1,
        1: 1,
        2: 0,
        3: 0,
        4: 0
      },
      trouser_waist: {
        0: 1,
        1: 1,
        2: 0,
        3: 0,
        4: 0
      },
      waist: {
        0: undefined,
        1: undefined,
        2: undefined,
        3: undefined,
        4: undefined
      },
      hip: {
        0: undefined,
        1: undefined,
        2: undefined,
        3: undefined,
        4: undefined
      }
    },
    '8956158574874': {
      hip: {
        0: undefined,
        1: undefined,
        2: undefined,
        3: undefined,
        4: undefined
      },
      trouser_waist: {
        0: 1,
        1: 1,
        2: 0,
        3: 0,
        4: 0
      },
      waist: {
        0: 1,
        1: 1,
        2: 0,
        3: 0,
        4: 0
      },
      chest: {
        0: 1,
        1: 1,
        2: 0,
        3: 0,
        4: 0
      }
    },
    '8956160442650': {
      chest: {
        0: 1,
        1: 1,
        2: 0,
        3: 0,
        4: 0
      },
      trouser_waist: {
        0: 1,
        1: 1,
        2: 0,
        3: 0,
        4: 0
      },
      waist: {
        0: 1,
        1: 1,
        2: 0,
        3: 0,
        4: 0
      },
      hip: {
        0: undefined,
        1: undefined,
        2: undefined,
        3: undefined,
        4: undefined
      }
    },
    '8956161229082': {
      chest: {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0
      },
      trouser_waist: {
        0: 0,
        1: 0,
        2: 1,
        3: 0,
        4: 0
      },
      waist: {
        0: undefined,
        1: undefined,
        2: undefined,
        3: undefined,
        4: undefined
      },
      hip: {
        0: undefined,
        1: undefined,
        2: undefined,
        3: undefined,
        4: undefined
      }
    },
    '8956161786138': {
      chest: {
        0: 2,
        1: 2,
        2: 1,
        3: 1,
        4: 0
      },
      trouser_waist: {
        0: 2,
        1: 2,
        2: 1,
        3: 1,
        4: 0
      },
      waist: {
        0: 1,
        1: 1,
        2: 0,
        3: 0,
        4: 0
      },
      hip: {
        0: undefined,
        1: undefined,
        2: undefined,
        3: undefined,
        4: undefined
      }
    },
    '8956162769178': {
      chest: {
        0: 1,
        1: 1,
        2: 0,
        3: 0,
        4: 0
      },
      trouser_waist: {
        0: 1,
        1: 1,
        2: 0,
        3: 0,
        4: 0
      },
      waist: {
        0: undefined,
        1: undefined,
        2: undefined,
        3: undefined,
        4: undefined
      },
      hip: {
        0: undefined,
        1: undefined,
        2: undefined,
        3: undefined,
        4: undefined
      }
    },
    '8956163293466': {
      chest: {
        0: undefined,
        1: undefined,
        2: undefined,
        3: undefined,
        4: undefined
      },
      trouser_waist: {
        0: 1,
        1: 1,
        2: 0,
        3: 0,
        4: 0
      },
      waist: {
        0: 1,
        1: 1,
        2: 0,
        3: 0,
        4: 0
      },
      hip: {
        0: 1,
        1: 1,
        2: 0,
        3: 0,
        4: 0
      }
    },
    '8956164112666': {
      chest: {
        0: 1,
        1: 1,
        2: 0,
        3: 0,
        4: 0
      },
      trouser_waist: {
        0: undefined,
        1: undefined,
        2: undefined,
        3: undefined,
        4: undefined
      },
      waist: {
        0: 1,
        1: 1,
        2: 0,
        3: 0,
        4: 0
      },
      hip: {
        0: undefined,
        1: undefined,
        2: undefined,
        3: undefined,
        4: undefined
      }
    },
    '8956164833562': {
      chest: {
        0: undefined,
        1: undefined,
        2: undefined,
        3: undefined,
        4: undefined
      },
      trouser_waist: {
        0: undefined,
        1: undefined,
        2: undefined,
        3: undefined,
        4: undefined
      },
      waist: {
        0: undefined,
        1: undefined,
        2: undefined,
        3: undefined,
        4: undefined
      },
      hip: {
        0: 1,
        1: 1,
        2: 0,
        3: 0,
        4: 0
      }
    },
    '9098393092378': {
      chest: {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0
      },
      trouser_waist: {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0
      },
      waist: {
        0: undefined,
        1: undefined,
        2: undefined,
        3: undefined,
        4: undefined
      },
      hip: {
        0: undefined,
        1: undefined,
        2: undefined,
        3: undefined,
        4: undefined
      }
    }
  };

  // copied from dev branch
  const updateModalImage = (gender: string | null | undefined) => {
    if (!gender) setModalImage(null);
    if (appContext) {
      setModalImage(
        gender &&
          typeof gender === 'string' &&
          appContext.flow?.genders &&
          Object.keys(appContext.flow.genders).includes(gender)
          ? appContext.flow.genders[
              gender as keyof typeof appContext.flow.genders
            ]
          : appContext.flow?.genders &&
            Object.keys(appContext.flow.genders).includes('default')
          ? appContext.flow.genders['default']
          : null
      );
    }
  };

  const userStored = getLocalAndParse('user');
  const isMobile = useMediaQuery({ maxWidth: 1000 });
  const isSmallMobile = useMediaQuery({ maxWidth: 374 });

  const { t } = useTranslation('components/modal');

  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState<string>(i18n.language.split('-')[0]);
  const [recommendedSize, setRecommendedSize] = useState<any>();
  const urlParameters = new URLSearchParams(window.location.search);
  const [modalConfigData, setModalConfigData] = useState({
    isShowScanButton: false,
    isShowQAButton: false
  });
  const [resultsVariant, setResultVarian] = useState<ResultVariant | undefined>(
    undefined
  );

  const MODAL_STEPS = {
    INTRO: { number: 0 },
    GENDER: { number: 1 },
    MOBILE_CHOICE: { number: 2 },
    QR_CODE: { number: 3 },
    HEIGHT: { number: 4 },
    WEIGHT: { number: 5 },
    AGE: { number: 6 },
    BELLY: { number: 7 },
    TORSO: { number: 8 },
    HIPS: { number: 9 },
    BREASTS: { number: 10 },
    WAIT: { number: 11 },
    LOADING: { number: 12 },
    ERROR: { number: 13 },
    RESULT: { number: 14 },
    PRODUCTS: { number: 15 },
    QUESTIONS: { number: 16 }
  };
  const MODAL_STEPS_TITLE = [
    t('steps.intro'),
    t('steps.gender'),
    t('steps.mobile_choice'),
    t('steps.qr_code'),
    t('steps.height'),
    t('steps.weight'),
    t('steps.age'),
    t('steps.belly'),
    t('steps.torso'),
    t('steps.hips'),
    t('steps.breasts'),
    t('steps.wait'),
    t('steps.loading'),
    t('steps.error'),
    t('steps.result.available'),
    t('steps.products'),
    t('steps.questions')
  ];

  const [step, setStep] = useState(MODAL_STEPS.WAIT); // MODAL_STEPS.WAIT
  const [showBackground, setShowBackground] = useState(true);

  const [modalImage, setModalImage] = useState<string | undefined | null>(
    undefined
  );
  const [previousStep, setPreviousStep] = useState<{
    number: number;
  } | null>(null);
  const [selectedHeightFormat, setSelectedHeightFormat] = useState<
    'feet' | 'cm'
  >('cm');
  const [selectedWeightFormat, setSelectedWeightFormat] = useState<
    'lbs' | 'kg'
  >('kg');

  const userContext = useUserContext() || undefined;
  const user = userContext?.user;
  const appContext = useAppContext() || undefined;

  const gender = useRef(user?.gender);
  const age = useRef(user?.age);
  const height = useRef(user?.height);
  const weight = useRef(user?.weight);
  const units = useRef({ height: '', weight: '' });
  const bellyValue = useRef(0);
  const chestValue = useRef(0);
  const hipValue = useRef(0);
  const sizeValue = useRef('');
  const cupValue = useRef('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const refererLang = urlParams.get('lang');

    i18n.changeLanguage(refererLang ?? i18n.language.split('-')[0]);
  }, []);

  useEffect(() => {
    if (userContext) {
      const urlParams = new URLSearchParams(window.location.search);
      const domain = urlParams.get('domain');

      if (
        !localStorage.getItem('uid') ||
        localStorage.getItem('domain') !== domain
      ) {
        const urlParams = new URLSearchParams(window.location.search);
        const domain = urlParams.get('domain');
        const uid = userContext
          .newUser(domain)
          .then((v: any) => {
            userContext.newMeasure('scan', v || '');
          })
          .catch((e: any) => console.log(e));
      }

      if (!localStorage.getItem('mid')) {
        userContext.newMeasure('scan', '');
      }
    }
  }, [userContext]);

  const sendQuestionAnswers = async () => {
    const INCHtoCM = (v: any) => v[0] * 30.48 + v[1] * 2.54;
    const LBStoKG = (v: number) => v * 0.453592;
    const data: {
      gender?: string;
      age?: number;
      height?: number;
      weight?: number;
      questions: {
        male_belly: string | null;
        male_hip: string | null;
        male_chest: string | null;
        female_belly: string | null;
        female_hip: string | null;
        female_bra_size?: string | null;
        female_bra_cup?: string | null;
      };
    } = {
      gender: gender.current,
      age: age.current,
      height:
        selectedHeightFormat === 'feet'
          ? INCHtoCM(height.current)
          : height.current,
      weight:
        selectedWeightFormat === 'lbs' && weight.current
          ? LBStoKG(weight.current)
          : weight.current,
      questions: {
        male_belly:
          bellyValue.current === 0 ? '2' : bellyValue.current.toString(),
        male_hip: hipValue.current === 0 ? '2' : hipValue.current.toString(),
        male_chest:
          chestValue.current === 0 ? '2' : chestValue.current.toString(),
        female_belly:
          bellyValue.current === 0 ? '2' : bellyValue.current.toString(),
        female_hip: hipValue.current === 0 ? '2' : hipValue.current.toString(),
        female_bra_size: sizeValue.current,
        female_bra_cup: cupValue.current === 'L' ? 'K' : cupValue.current
      }
    };
    if (gender.current == GENDERS.F) {
      data.questions.male_belly = '';
      data.questions.male_hip = '';
      data.questions.male_chest = '';

      if (sizeValue.current === '' && cupValue.current === '') {
        data.questions.female_bra_size = '80';
        data.questions.female_bra_cup = 'B';
      }
    } else {
      data.questions.female_belly = '';
      data.questions.female_hip = '';
      data.questions.female_bra_size = '';
      data.questions.female_bra_cup = '';

      if (hipValue.current === 0) {
        data.questions.male_hip = '2';
      }
    }

    const result = await questionPushAnswersAndTrigger(data);
    return result;
  };

  const [loadingContent, setLoadingContent] = useState({
    title: 'Veuillez patienter...',
    color: 'text-gray-700'
  });

  const [contentChange, setContentChange] = useState(false);

  const restart = () => {
    setLoading(false);

    setStep(MODAL_STEPS.INTRO);
    bellyValue.current = 0;
    chestValue.current = 0;
    hipValue.current = 0;
    sizeValue.current = '';
    cupValue.current = '';
    gender.current = '';
    removeLocalStore('mid');
    removeLocalStore('user');
    removeLocalStore('productSize');

    const urlParams = new URLSearchParams(window.location.search);
    removeLocalStore(`${urlParams.get('pid')}`);

    if (localStorage.getItem('user')) {
      setLocalStore('user', JSON.stringify(user));
    }
  };

  const handleGoBack = () => {
    if (step.number === MODAL_STEPS.HEIGHT.number) {
      setStep(MODAL_STEPS.INTRO);
    } else if (step.number === MODAL_STEPS.WEIGHT.number) {
      setStep(MODAL_STEPS.HEIGHT);
    } else if (step.number === MODAL_STEPS.AGE.number) {
      setStep(MODAL_STEPS.WEIGHT);
    } else if (step.number === MODAL_STEPS.BELLY.number) {
      setStep(MODAL_STEPS.QR_CODE);
    } else if (step.number === MODAL_STEPS.HIPS.number) {
      setStep(MODAL_STEPS.BELLY);
    } else if (step.number === MODAL_STEPS.TORSO.number) {
      setStep(MODAL_STEPS.HIPS);
    } else if (step.number === MODAL_STEPS.BREASTS.number) {
      setStep(MODAL_STEPS.HIPS);
    } else if (step.number === MODAL_STEPS.QR_CODE.number) {
      if (previousStep !== null) {
        setStep(previousStep);
        setPreviousStep(null);
      } else {
        setStep(MODAL_STEPS.QUESTIONS);
      }
    } else if (step.number === MODAL_STEPS.RESULT.number) {
      restart();
    } else if (step.number === MODAL_STEPS.PRODUCTS.number) {
      setStep(MODAL_STEPS.RESULT);
    } else if (step.number === MODAL_STEPS.QUESTIONS.number) {
      setStep(MODAL_STEPS.INTRO);
    }
  };

  useEffect(() => {
    if (
      userContext &&
      (step.number === MODAL_STEPS.INTRO.number ||
        step.number === MODAL_STEPS.QR_CODE.number)
    )
      userContext.newMeasure('scan', '');
  }, [step, userContext]);

  const [reducedResult, setReducedResult] = useState<any>();
  useEffect(() => {
    if (recommendedSize) {
      const reducedResultRes: any = {
        1: recommendedSize[0]
      };

      if (recommendedSize[1].label_rank > recommendedSize[0].label_rank) {
        reducedResultRes[2] = recommendedSize[1];
      } else {
        reducedResultRes[0] = recommendedSize[1];
      }

      if (recommendedSize.length === 3) {
        if (recommendedSize[2].label_rank > recommendedSize[0].label_rank) {
          reducedResultRes[2] = recommendedSize[2];
        } else {
          reducedResultRes[0] = recommendedSize[2];
        }
      }
      setReducedResult(reducedResultRes);
    }
  }, [recommendedSize]);

  const [feedbacks, setFeedbacks] = useState<any>();
  const [colors, setColors] = useState<any>();
  const [focused, setFocused] = useState(1);
  useEffect(() => {
    if (reducedResult && focused !== undefined) {
      const focusedFeeback = reducedResult[focused].fit_indicators;
      const result = focusedFeeback.reduce((acc: any, { limb, value }: any) => {
        acc[limb] = value;
        return acc;
      }, {});
      const colorMapping: { [key: number]: string } = {
        0: '#FFEEE2',
        1: '#FFF9E2',
        2: '#C0E2C3',
        3: '#FFF9E2',
        4: '#FFEEE2'
      };
      const colors = Object.keys(result).reduce(
        (newObj: { [key: string]: string }, key: string) => {
          const value = result[key];
          newObj[key] = colorMapping[value];
          return newObj;
        },
        {}
      );
      setFeedbacks(result);
      setColors(colors);
    }
  }, [reducedResult, focused]);

  const getPoweredByTextPosition = () => {
    if (isMobile) {
      return '50%';
    } else if (step.number === MODAL_STEPS.QUESTIONS.number) {
      return '79%';
    } else {
      return '79%';
    }
  };

  const getModalHeight = () => {
    if (isMobile && step.number === MODAL_STEPS.INTRO.number) {
      return '394px';
    } else if (step.number === MODAL_STEPS.RESULT.number) {
      return '554px';
    } else if (isMobile) {
      return '541px';
    } else {
      return '502px';
    }
  };

  const handleGetBgImage = async () => {
    const customerId = urlParameters.get('customer_id');
    const pid = urlParameters.get('product_id');
    const domain = urlParameters.get('domain');

    if (!pid || !domain || !customerId) return console.log('No Params');

    const productImage = await getProductImage(pid, domain, customerId);

    return productImage;
  };

  useEffect(() => {
    async function handleSetModalImage() {
      const resultModalImage = await handleGetBgImage();

      if (step.number === MODAL_STEPS.RESULT.number && resultModalImage) {
        setModalImage(resultModalImage);
        return;
      }

      if (step.number !== 0) {
        updateModalImage(null);
      } else if (step.number === MODAL_STEPS.INTRO.number) {
        if (gender.current) {
          updateModalImage(gender?.current);
        } else {
          updateModalImage('default');
        }
      }
    }

    handleSetModalImage();
  }, [step, gender]);

  useEffect(() => {
    sessionStorage.setItem('firstLoad', 'true');
  }, []);

  return (
    <Dialog
      aria-labelledby="klipfit-modal"
      onClose={() => handleModal(!isModalOpen, false)}
      open={isModalOpen}
      PaperProps={{
        style: {
          position: isMobile ? 'fixed' : undefined,
          margin: isMobile ? '0' : undefined,
          bottom: isMobile ? '0%' : undefined,
          minWidth: isMobile ? '100%' : '845px',
          borderRadius: isMobile ? '16px 16px 0 0' : '10px',
          maxWidth: isMobile ? '100%' : '845px',
          maxHeight: '100%',
          overflow: 'hidden',
          padding: 0
        }
      }}
    >
      <Suspense>
        <DialogContent
          sx={{
            background: !isMobile
              ? `white url(${modalImage}) no-repeat 0px / cover`
              : 'white',
            height: getModalHeight(),
            minWidth: '100%',
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'start',
            color:
              isMobile && step.number === MODAL_STEPS.INTRO.number
                ? 'white'
                : null,
            flexDirection:
              step.number === MODAL_STEPS.INTRO.number ||
              step.number === MODAL_STEPS.RESULT.number
                ? 'row'
                : 'column',
            padding: '0px!important',
            paddingBottom: isMobile ? '10px' : '0'
            // transition: 'background-image 0.3s ease-in'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              height: '100%'
            }}
          >
            <Box
              sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'flex-end',
                flexDirection: 'column',
                overflow: 'hidden',
                cursor: 'default'
              }}
            >
              {step.number === MODAL_STEPS.RESULT.number &&
                loading &&
                !getLocalAndParse('productSize') && (
                  <div
                    className={`flex flex-col items-center justify-center text-center font-unbounded p-[80px] ${
                      loadingContent.title == 'Loading'
                        ? 'sm:px-[200px] sm:py-[100px]'
                        : null
                    }`}
                    role="status"
                  >
                    {!contentChange && (
                      <>
                        <Lottie
                          animationData={require('../../assets/animations/loading_black.json')}
                          style={{
                            width: '200px',
                            height: 'auto'
                          }}
                        />
                        <span className="sr-only">{loadingContent.title}</span>
                      </>
                    )}
                    <h3
                      className={`mt-2  text-lg font-semibold ${loadingContent.color}`}
                    >
                      {loadingContent.title}
                    </h3>
                  </div>
                )}
              {step.number === MODAL_STEPS.WAIT.number && (
                <Wait
                  domain={urlParameters.get('domain')}
                  setConfig={(data) => console.log(data)}
                  intro={() => setStep(MODAL_STEPS.INTRO)}
                  result={(r: any) => setRecommendedSize(r)}
                  setModalConfigData={setModalConfigData}
                  setResultVariant={setResultVarian}
                  gender={() => setStep(MODAL_STEPS.GENDER)}
                  handleNavigateToResult={() => setStep(MODAL_STEPS.RESULT)}
                />
              )}
              {isMobile && step.number === MODAL_STEPS.INTRO.number ? (
                <StepIntroMobile
                  nextStep={() => setStep(MODAL_STEPS.QUESTIONS)}
                  setLocalGender={(value: string) => {
                    // setModalImage(value === 'male' ? homme : femme);
                    gender.current = value;
                  }}
                  header={
                    <ModalHeader
                      hideArrowBack={step.number === MODAL_STEPS.INTRO.number}
                      handleClose={handleModal}
                      handleGoBack={handleGoBack}
                    />
                  }
                />
              ) : (
                step.number === MODAL_STEPS.INTRO.number && (
                  <StepIntroDesktop
                    nextStep={() => setStep(MODAL_STEPS.QUESTIONS)}
                    setLocalGender={(value: string) => {
                      // setModalImage(value === 'male' ? homme : femme);
                      gender.current = value;
                    }}
                    header={
                      <ModalHeader
                        hideArrowBack={step.number === MODAL_STEPS.INTRO.number}
                        handleClose={handleModal}
                        handleGoBack={handleGoBack}
                      />
                    }
                  />
                )
              )}
              {step.number === MODAL_STEPS.QUESTIONS.number && (
                <StepQuestions
                  object={[
                    {
                      name: 'height',
                      min: selectedHeightFormat === 'feet' ? 47 : 140,
                      max: selectedHeightFormat === 'feet' ? 77 : 230,
                      inputRef: height,
                      unitVariants: ['cm', 'feet'],
                      onUnitVariantChange: setSelectedHeightFormat,
                      unit: selectedHeightFormat
                    },
                    {
                      name: 'weight',
                      min: selectedWeightFormat === 'lbs' ? 88 : 40,
                      max: selectedWeightFormat === 'lbs' ? 309 : 140,
                      title: 'Votre poids',
                      inputRef: weight,
                      unit: selectedWeightFormat,
                      unitVariants: ['kg', 'lbs'],
                      onUnitVariantChange: setSelectedWeightFormat
                    },
                    {
                      name: 'age',
                      min: 16,
                      max: 80,
                      inputRef: age
                    }
                  ]}
                  nextStep={async () => {
                    if (modalConfigData.isShowQAButton) {
                      setStep(MODAL_STEPS.QR_CODE);
                    } else {
                      const maleBody = {
                        female_belly: '',
                        female_bra_cup: '',
                        female_bra_size: '',
                        female_hip: '',
                        male_belly: '2',
                        male_chest: '2',
                        male_hip: '2'
                      };

                      const femaleBody = {
                        female_belly: '2',
                        female_bra_cup: 'B',
                        female_bra_size: '80',
                        female_hip: '2',
                        male_belly: '',
                        male_chest: '',
                        male_hip: ''
                      };

                      const body =
                        gender.current === 'male' ? maleBody : femaleBody;

                      await newMeasure('question');

                      // await questionPushAnswersAndTrigger({
                      //   age: age.current,
                      //   gender: gender.current,
                      //   height: height.current,
                      //   weight: weight.current,
                      //   questions: {
                      //     ...body
                      //   }
                      // });

                      setStep(MODAL_STEPS.LOADING);
                    }
                  }}
                  header={
                    <ModalHeader
                      hideArrowBack={step.number === MODAL_STEPS.INTRO.number}
                      handleClose={handleModal}
                      handleGoBack={handleGoBack}
                    />
                  }
                />
              )}
              {step.number === MODAL_STEPS.WEIGHT.number && (
                <StepWeight
                  nextStep={() => setStep(MODAL_STEPS.AGE)}
                  units={units}
                  weight={weight}
                  header={
                    <ModalHeader
                      hideArrowBack={step.number === MODAL_STEPS.INTRO.number}
                      handleClose={handleModal}
                      handleGoBack={handleGoBack}
                    />
                  }
                />
              )}
              {step.number === MODAL_STEPS.AGE.number && (
                <StepAge
                  nextStep={() => setStep(MODAL_STEPS.QR_CODE)}
                  age={age}
                  header={
                    <ModalHeader
                      hideArrowBack={step.number === MODAL_STEPS.INTRO.number}
                      handleClose={handleModal}
                      handleGoBack={handleGoBack}
                    />
                  }
                />
              )}
              {step.number === MODAL_STEPS.BELLY.number && (
                <StepBelly
                  gender={gender.current || ''}
                  nextStep={() => {
                    if (gender.current === 'male') {
                      setStep(MODAL_STEPS.HIPS);
                    } else {
                      setStep(MODAL_STEPS.HIPS);
                    }
                  }}
                  skip={() => {
                    setPreviousStep(MODAL_STEPS.BELLY);
                    setStep(MODAL_STEPS.QR_CODE);
                  }}
                  value={bellyValue}
                  isShowScanButton={modalConfigData.isShowScanButton}
                  header={
                    <ModalHeader
                      hideArrowBack={step.number === MODAL_STEPS.INTRO.number}
                      handleClose={handleModal}
                      handleGoBack={handleGoBack}
                    />
                  }
                />
              )}
              {step.number === MODAL_STEPS.HIPS.number && (
                <StepHips
                  gender={gender.current || ''}
                  nextStep={() => {
                    if (gender.current === 'male') {
                      setStep(MODAL_STEPS.TORSO);
                    } else {
                      setStep(MODAL_STEPS.BREASTS);
                    }
                  }}
                  skip={() => {
                    setPreviousStep(MODAL_STEPS.HIPS);
                    setStep(MODAL_STEPS.QR_CODE);
                  }}
                  value={hipValue}
                  isShowScanButton={modalConfigData.isShowScanButton}
                  header={
                    <ModalHeader
                      hideArrowBack={step.number === MODAL_STEPS.INTRO.number}
                      handleClose={handleModal}
                      handleGoBack={handleGoBack}
                    />
                  }
                />
              )}
              {step.number === MODAL_STEPS.TORSO.number && (
                <StepTorso
                  nextStep={async () => {
                    await newMeasure('question');

                    setStep(MODAL_STEPS.LOADING);
                  }}
                  skip={() => {
                    setPreviousStep(MODAL_STEPS.TORSO);
                    setStep(MODAL_STEPS.QR_CODE);
                  }}
                  gender={gender.current || ''}
                  value={chestValue}
                  isShowScanButton={modalConfigData.isShowScanButton}
                  header={
                    <ModalHeader
                      hideArrowBack={step.number === MODAL_STEPS.INTRO.number}
                      handleClose={handleModal}
                      handleGoBack={handleGoBack}
                    />
                  }
                />
              )}
              {step.number === MODAL_STEPS.BREASTS.number && (
                <StepBreasts
                  value_1={sizeValue}
                  value_2={cupValue}
                  nextStep={async () => {
                    await newMeasure('question');

                    setStep(MODAL_STEPS.LOADING);
                  }}
                  skipStep={async () => {
                    await newMeasure('question');

                    setStep(MODAL_STEPS.LOADING);
                  }}
                  header={
                    <ModalHeader
                      hideArrowBack={step.number === MODAL_STEPS.INTRO.number}
                      handleClose={handleModal}
                      handleGoBack={handleGoBack}
                    />
                  }
                />
              )}
              {step.number === MODAL_STEPS.QR_CODE.number && (
                <StepQRCode
                  showSkip={previousStep === null}
                  pid={pid}
                  skipScan={() => setStep(MODAL_STEPS.BELLY)}
                  skipToResult={() => setStep(MODAL_STEPS.RESULT)}
                  setRecommendedSize={setRecommendedSize}
                  header={
                    <ModalHeader
                      hideArrowBack={step.number === MODAL_STEPS.INTRO.number}
                      handleClose={handleModal}
                      handleGoBack={handleGoBack}
                    />
                  }
                />
              )}
              {step.number === MODAL_STEPS.LOADING.number && (
                <ResultLoading
                  nextStep={(value: boolean) => {
                    const nextStep = value
                      ? MODAL_STEPS.RESULT
                      : MODAL_STEPS.ERROR;
                    setStep(nextStep);
                  }}
                  sendAnswers={sendQuestionAnswers}
                  setRecommendedSize={setRecommendedSize}
                  setResultVariant={setResultVarian}
                  gender={() => setStep(MODAL_STEPS.INTRO)}
                  domain={urlParameters.get('domain')}
                  handleNavigateToResult={() => setStep(MODAL_STEPS.RESULT)}
                />
              )}
              {step.number === MODAL_STEPS.ERROR.number && (
                <ResultError
                  refresh={() => setStep(MODAL_STEPS.LOADING)}
                  quit={() => handleModal(false, false)}
                />
              )}
              {step.number === MODAL_STEPS.RESULT.number && (
                <NewResultsDesign
                  nextStep={() => setStep(MODAL_STEPS.PRODUCTS)}
                  restart={restart}
                  reducedResult={reducedResult}
                  focused={focused}
                  setFocused={setFocused}
                  product={pid ? lacosteProducts[pid] : null}
                  productFeedback={pid ? product_feedbacks[pid] : null}
                  fitFeedback={fit_feedbacks}
                  feedbacks={feedbacks}
                  colors={colors}
                  resultVariant={resultsVariant}
                  bgImageUrl={modalImage}
                  header={
                    <ModalHeader
                      hideArrowBack={step.number === MODAL_STEPS.RESULT.number}
                      handleClose={handleModal}
                      handleGoBack={handleGoBack}
                    />
                  }
                  domain={urlParameters.get('domain')}
                />
              )}
              {step.number === MODAL_STEPS.PRODUCTS.number && (
                <Products pid={pid} />
              )}

              {step.number !== MODAL_STEPS.INTRO.number &&
                step.number !== MODAL_STEPS.RESULT.number &&
                step.number !== MODAL_STEPS.ERROR.number &&
                step.number !== MODAL_STEPS.LOADING.number &&
                step.number !== MODAL_STEPS.WAIT.number && (
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: '12px',
                      left: getPoweredByTextPosition(),
                      transform: 'translateX(-50%)',
                      display:
                        (!isMobile &&
                          step.number === MODAL_STEPS.QR_CODE.number) ||
                        (isMobile && step.number === MODAL_STEPS.BREASTS.number)
                          ? 'none'
                          : 'flex',
                      columnGap: '1px'
                    }}
                  >
                    <Typography
                      fontFamily="Roboto Flex"
                      fontSize="10px"
                      fontWeight={300}
                      sx={{
                        opacity: 0.7,
                        textAlign: 'center',
                        marginBottom: '3px',
                        cursor: 'default',
                        zIndex: 10
                      }}
                    >
                      {t('poweredBy').replace('- ', '')}
                    </Typography>
                    <img
                      style={{
                        width: '32px',
                        marginLeft: '3px',
                        marginBottom: '4px',
                        height: 'auto',
                        filter:
                          isMobile && step.number === MODAL_STEPS.INTRO.number
                            ? 'invert(1)'
                            : undefined,
                        zIndex: 10
                      }}
                      src={require('../../assets/logo/kleep.png')}
                    />
                  </Box>
                )}
            </Box>
          </Box>
        </DialogContent>
      </Suspense>
    </Dialog>
  );
};

export default KleepModal;
