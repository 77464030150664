import React, { FC, useState } from 'react';

import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

import hips_1_female from '../../../assets/questionnaire/hips/hips-1-female.svg';
import hips_2_female from '../../../assets/questionnaire/hips/hips-2-female.svg';
import hips_3_female from '../../../assets/questionnaire/hips/hips-3-female.svg';

import hips_1_male from '../../../assets/questionnaire/hips/hips-1-male.svg';
import hips_2_male from '../../../assets/questionnaire/hips/hips-2-male.svg';
import hips_3_male from '../../../assets/questionnaire/hips/hips-3-male.svg';

import './stepHips.scss';
import ImageChoiceWrapper from '../../ImageChoiceWrapper';
import { ImageChoiceData } from '../../../Types/ImageChoice';

interface IPropsStepHips {
  gender: string;
  nextStep: () => void;
  skip: () => void;
  value: {
    current: number;
  };
  header: React.ReactNode;
  isShowScanButton: boolean;
}

const StepHips: FC<IPropsStepHips> = ({
  gender,
  nextStep,
  skip,
  value,
  header,
  isShowScanButton
}) => {
  const isMobile = useMediaQuery({ maxWidth: 1000 });
  const { t } = useTranslation('components/hips');
  const [selected, setSelected] = useState<number | undefined>(value.current);

  const data: ImageChoiceData = [
    {
      image: gender === 'male' ? hips_1_male : hips_1_female,
      isSelected: selected === 1,
      onClick: () => {
        value.current = 1;
        setSelected(1);
      },
      text: t('size.one')
    },
    {
      image: gender === 'male' ? hips_2_male : hips_2_female,
      isSelected: selected === 2,
      onClick: () => {
        value.current = 2;
        setSelected(2);
      },
      text: t('size.two')
    },
    {
      image: gender === 'male' ? hips_3_male : hips_3_female,
      isSelected: selected === 3,
      onClick: () => {
        value.current = 3;
        setSelected(3);
      },
      text: t('size.three')
    }
  ];

  return (
    <div className="hips">
      {header}
      <ImageChoiceWrapper
        data={data}
        description={t('description')}
        gender={gender}
        nextStep={nextStep}
        selected={selected}
        skip={skip}
        subtitle={t('subtitle')}
        title={t('title')}
        currentStep={2}
        isShowScanButton={isShowScanButton}
      />
    </div>
  );
};

export default StepHips;
