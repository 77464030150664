import React, { FC, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { getLocalAndParse } from '../../../../store/localStoreUtils';
import { useUserContext } from '../../../../store/userContext';

import './stepIntro.scss';
import ModalHeader from '../../header';

interface IPropsStepIntroDesktop {
  nextStep: () => void;
  setLocalGender: (value: string) => void;
  header: React.ReactNode;
}

const StepIntroDesktop: FC<IPropsStepIntroDesktop> = ({
  nextStep,
  setLocalGender,
  header
}) => {
  const { t } = useTranslation('components/intro');
  const { setUser } = useUserContext() || {};
  const userStored = getLocalAndParse('user');
  const isFirstLoad = sessionStorage.getItem('firstLoad');

  const [selectGender, setSelectGender] = useState<string | undefined>(
    undefined
  );
  const [transformValue, setTransformValue] = useState(
    isFirstLoad === 'true' ? 'translateX(101%)' : undefined
  );
  const [opacity, setOpactity] = useState(isFirstLoad === 'true' ? 0 : 1);

  useEffect(() => {
    if (isFirstLoad === 'true') {
      requestAnimationFrame(() => {
        setTransformValue('translateX(0%)'); // Déplace vers la position initiale
      });
      sessionStorage.setItem('firstLoad', 'false');

      setTimeout(() => {
        setOpactity(1);
      }, 800);
    }
  }, [isFirstLoad]);

  const handleNext = (gender: string) => {
    setLocalGender(gender);
    setSelectGender(gender);

    const newUser = {
      ...userStored,
      gender: gender
    };

    localStorage.setItem('user', JSON.stringify(newUser));
    setLocalGender(gender);

    if (setUser) {
      setUser(newUser);
    }

    gender !== undefined && nextStep();
  };

  return (
    <div className="intro-desktop">
      <div
        className="intro-desktop__container"
        style={{
          transform: transformValue,
          transition: 'transform 1.5s ease'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            opacity: opacity,
            transition: 'opacity 0.5s ease'
          }}
        >
          {header}
          <h1 className="intro-desktop__container__title">
            <Trans
              ns="components/intro"
              i18nKey="desktop.title"
              values={{ separator: '\n' }}
            />
          </h1>
          <div className="intro-desktop__container__buttons">
            <button
              className={`intro-desktop__container__buttons__button ${
                selectGender === 'male'
                  ? 'intro-desktop__container__buttons__button--active'
                  : undefined
              }`}
              onClick={() => {
                handleNext('male');
              }}
            >
              {t('gender.male')}
            </button>
            <button
              className={`intro-desktop__container__buttons__button ${
                selectGender === 'female'
                  ? 'intro-desktop__container__buttons__button--active'
                  : undefined
              }`}
              onClick={() => {
                // handleNext(selectGender || '');
                handleNext('female');
              }}
            >
              {t('gender.female')}
            </button>
          </div>
          <div className="intro-desktop__container__info">
            <div className="intro-desktop__container__privacy-policy">
              <p>
                {t('pp_part1')}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.kleep.ai/legal"
                  style={{ textDecoration: 'underline' }}
                >
                  {t('pp_link')}
                </a>
              </p>
            </div>
            <div className="intro-desktop__container__measured-by">
              <p>Measured by</p>
              <p className="intro-desktop__container__measured-by__kleep">
                kleep
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepIntroDesktop;
