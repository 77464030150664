import React, { FC, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { getLocalAndParse } from '../../../../store/localStoreUtils';
import { useUserContext } from '../../../../store/userContext';

import './stepIntro.scss';
import ModalHeader from '../../header';

interface IPropsStepIntroMobile {
  nextStep: () => void;
  setLocalGender: (value: string) => void;
  header: React.ReactNode;
}

const StepIntroMobile: FC<IPropsStepIntroMobile> = ({
  nextStep,
  setLocalGender,
  header
}) => {
  const { t } = useTranslation('components/intro');
  const { setUser } = useUserContext() || {};
  const userStored = getLocalAndParse('user');
  const [selectGender, setSelectGender] = useState<string | undefined>(
    userStored?.gender
  );

  const handleNext = (gender: string) => {
    setLocalGender(gender);
    setSelectGender(gender);

    const newUser = {
      ...userStored,
      gender: gender
    };

    localStorage.setItem('user', JSON.stringify(newUser));
    setLocalGender(gender);

    if (setUser) {
      setUser(newUser);
    }

    gender !== undefined && nextStep();
  };

  return (
    <div className="intro-mobile">
      {header}
      <div className="intro-mobile__text">
        <h1 className="intro-mobile__text__title">
          <Trans
            ns="components/intro"
            i18nKey="mobile.title"
            values={{ separator: '\n' }}
          />
        </h1>
      </div>
      <div className="intro-mobile__buttons">
        <button
          className={`intro-mobile__buttons__button ${
            selectGender === 'male'
              ? 'intro-mobile__buttons__button--active'
              : undefined
          }`}
          onClick={() => {
            handleNext('male');
          }}
        >
          {t('gender.male')}
        </button>
        <button
          className={`intro-mobile__buttons__button ${
            selectGender === 'female'
              ? 'intro-mobile__buttons__button--active'
              : undefined
          }`}
          onClick={() => {
            handleNext('female');
          }}
        >
          {t('gender.female')}
        </button>
      </div>
      <div className="intro-mobile__info">
        <div className="intro-mobile__privacy-policy">
          <p>
            {t('pp_part1')}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.kleep.ai/legal"
              style={{ textDecoration: 'underline' }}
            >
              {t('pp_link')}
            </a>
          </p>
        </div>
        <div className="intro-mobile__measured-by">
          <p>Measured by</p>
          <img
            style={{
              width: '32px',
              marginLeft: '3px',
              marginBottom: '4px',
              height: 'auto',
              zIndex: 10
            }}
            src={require('../../../../assets/logo/kleep.png')}
          />
        </div>
      </div>
    </div>
  );
};

export default StepIntroMobile;
