import React from 'react';

const ResultAutoIdeal: React.FC = () => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxWidth: '335px'
      }}
    >
      <div
        style={{
          fontWeight: 400,
          fontSize: '14px',
          textDecoration: 'underline',
          color: '#898989',
          cursor: 'pointer'
        }}
      >
        <p>Mettre à jour ma taille</p>
      </div>
      <div style={{ width: '1px', height: '17px', background: '#898989' }} />
      <div
        style={{
          fontWeight: 400,
          fontSize: '14px',
          textDecoration: 'underline',
          color: '#898989',
          cursor: 'pointer'
        }}
      >
        <p>Supprimer mes informations</p>
      </div>
    </div>
  );
};

export default ResultAutoIdeal;
