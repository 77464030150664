import React from 'react';
import { ImageChoiceData } from '../../Types/ImageChoice';
import './index.scss';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { GENDERS } from '../../utils';
import ChoiceItem from './ChoiceItem';
import Stepper from '../KleepModal/molecules/Stepper/Stepper';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';

interface Props {
  data: ImageChoiceData;
  title: string;
  description: string;
  subtitle: string;
  gender?: string | undefined;
  nextStep(): void;
  selected: number | undefined;
  skip(): void;
  currentStep: number;
  isShowScanButton: boolean;
}

const ImageChoiceWrapper: React.FC<Props> = ({
  data,
  description,
  subtitle,
  title,
  gender,
  nextStep,
  selected,
  skip,
  currentStep,
  isShowScanButton
}: Props) => {
  const isMobile = useMediaQuery({ maxWidth: 1000 });
  const { t } = useTranslation('components/belly');

  return (
    <div className="choice">
      <div className="choice__text">
        <p className="choice__text__description">{description}</p>
        <p className="choice__text__subtitle">{subtitle}</p>
      </div>
      <div className="choice__body">
        {data.map((item) => (
          <ChoiceItem
            key={item.text}
            image={item.image}
            isSelected={item.isSelected}
            onClick={item.onClick}
            text={item.text}
          />
        ))}
      </div>
      <Stepper
        stepsNum={gender === 'male' ? 3 : 2}
        step={currentStep}
        key={currentStep}
        className="choice__stepper"
      />
      <div className="choice__buttons">
        <button
          className="choice__buttons__scan"
          onClick={() => skip()}
          style={{ visibility: isShowScanButton ? 'visible' : 'hidden' }}
        >
          <CameraAltOutlinedIcon className="choice__buttons__scan__icon" />
          {t('buttons.scan')}
        </button>
        <button
          disabled={selected === undefined || selected === 0}
          className="choice__buttons__continue"
          onClick={() => nextStep()}
        >
          {t('buttons.continue')}
        </button>
      </div>
    </div>
  );
};

export default ImageChoiceWrapper;
