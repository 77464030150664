import React, { FC, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { getLocalAndParse } from '../../../../store/localStoreUtils';
import { useMediaQuery } from 'react-responsive';
import WeightDesktop from './desktop/desktop';
import WeightMobile from './mobile/mobile';
import EastRoundedIcon from '@mui/icons-material/EastRounded';

import './stepMeasure.scss';

interface IPropsStepWeight {
  nextStep: () => void;
  units: {
    current?: { height: string; weight: string };
  };
  weight: {
    current?: number;
  };
  header: React.ReactNode;
}

const StepWeight: FC<IPropsStepWeight> = ({
  nextStep,
  units,
  weight,
  header
}) => {
  const isMobile = useMediaQuery({ maxWidth: 1000 });
  const { t } = useTranslation('components/measures');
  const userStored = getLocalAndParse('user');
  const [localUnits, setLocalUnits] = useState(
    userStored?.units
      ? userStored?.units
      : {
          height: 'cm',
          weight: 'kg'
        }
  );
  const [localWeight, setLocalWeight] = useState(
    userStored?.weight !== undefined && userStored?.weight !== ''
      ? userStored?.weight
      : localUnits.weight === 'kg'
      ? 60
      : 132
  );
  const [error, setError] = useState({
    weight: {
      error: false,
      message: ''
    }
  });

  const KGtoLBS = (v: number) => v / 0.453592;

  const saveUserInformation = () => {
    const newUser = {
      ...userStored,
      weight: localWeight,
      units: localUnits
    };

    units.current = localUnits;
    weight.current = localWeight;
    localStorage.setItem('user', JSON.stringify(newUser));
  };

  const handleContinue = () => {
    const newError = {
      weight: {
        error: false,
        message: ''
      }
    };
    if (!localWeight) {
      newError.weight.error = true;
      newError.weight.message = t('errors.required');
    }
    if (localUnits.weight === 'kg' && (localWeight < 40 || localWeight > 140)) {
      newError.weight.error = true;
      newError.weight.message = t('errors.weight.kg');
    }
    if (
      localUnits.weight === 'lbs' &&
      (localWeight < 88 || localWeight > 309)
    ) {
      newError.weight.error = true;
      newError.weight.message = t('errors.weight.lbs');
    }

    setError(newError);

    if (!newError.weight.error) {
      saveUserInformation();
      nextStep();
    }
  };

  useEffect(() => {
    const newError = {
      weight: {
        error: false,
        message: ''
      }
    };
    if (!localWeight) {
      newError.weight.error = true;
      newError.weight.message = t('errors.required');
    }
    if (localUnits.weight === 'kg' && (localWeight < 40 || localWeight > 140)) {
      newError.weight.error = true;
      newError.weight.message = t('errors.weight.kg');
    }
    if (
      localUnits.weight === 'lbs' &&
      (localWeight < 88 || localWeight > 309)
    ) {
      newError.weight.error = true;
      newError.weight.message = t('errors.weight.lbs');
    }

    setError(newError);
  }, [localWeight]);

  return (
    <div className="measures-weight">
      {isMobile ? (
        <WeightMobile
          units={localUnits}
          setUnits={setLocalUnits}
          weight={[localWeight]}
          setWeight={setLocalWeight}
          error={error.weight}
          header={header}
        />
      ) : (
        <WeightDesktop
          units={localUnits}
          setUnits={setLocalUnits}
          weight={[localWeight]}
          setWeight={setLocalWeight}
          error={error.weight}
          header={header}
        />
      )}
      <button
        disabled={error.weight.error}
        className="measures-weight__continue"
        onClick={handleContinue}
      >
        <Trans
          ns="components/measures"
          i18nKey="weight.continue"
          values={{
            weight: localWeight,
            unit: localUnits.weight
          }}
        />
        <EastRoundedIcon className="measures-weight__continue__icon" />
      </button>
    </div>
  );
};

export default StepWeight;
