import React, { FC, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Text } from '../../molecules/Input/Text';

import './mobile.scss';

import { getLocalAndParse } from '../../../../store/localStoreUtils';
import InputWithRadioButtons from '../../molecules/InputWithRadioButtons';

interface IPropsQuestionsMobile {
  object?: any;
  nextStep: () => void;
  header: React.ReactNode;
}

const QuestionsMobile: FC<IPropsQuestionsMobile> = ({
  object,
  nextStep,
  header
}) => {
  const { t } = useTranslation('components/questions');

  const [disabled, setDisabled] = useState(
    object.reduce((acc: any, { name }: keyof object) => {
      acc[name] = false;
      return acc;
    }, {})
  );

  const handleNext = () => {
    const previousUser = getLocalAndParse('user');
    console.log('previousUser', previousUser);
    const newInputs = object.reduce((acc: any, { name, inputRef }: any) => {
      acc[name] = inputRef.current;
      return acc;
    }, {});
    const newUser = { ...previousUser, ...newInputs };
    localStorage.setItem('user', JSON.stringify(newUser));
    nextStep();
  };

  return (
    <div className="questions-mobile">
      <div className="questions-mobile__container">
        {header}
        <div className="questions-mobile__container__content">
          <h1 className="questions-mobile__container__content__title">
            <Trans
              ns="components/questions"
              i18nKey="title"
              values={{ separator: '\n' }}
            />
          </h1>
          <div className="questions-mobile__container__content__fields">
            {Object.keys(object).map((idx) => {
              return object[idx].unitVariants ? (
                <InputWithRadioButtons
                  key={idx}
                  object={object[idx]}
                  setDisabled={setDisabled}
                />
              ) : (
                <Text
                  key={idx}
                  object={object[idx]}
                  setDisabled={setDisabled}
                />
              );
            })}
          </div>
          <button
            onClick={handleNext}
            className="questions-mobile__container__content__button"
            disabled={Object.values(disabled).some((value) => value === true)}
          >
            {t('continue')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuestionsMobile;
