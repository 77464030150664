import React, { FC, useState, useEffect } from 'react';

import { useMediaQuery } from 'react-responsive';
import QrcodeMobile from './mobile/mobile';
import QrcodeDesktop from './desktop/desktop';

import { WEB_APP_URL } from '../../../utils';

import {
  newMeasure,
  recommend,
  scanCheckMeasurements
} from '../../../api/endpoints';

import { getLocalAndParse } from '../../../store/localStoreUtils';

interface IPropsStepQRCode {
  showSkip?: boolean;
  skipScan?: () => void;
  pid?: string;
  skipToResult: () => void;
  setRecommendedSize: (value: any) => void;
  header: React.ReactNode;
}

const StepQRCode: FC<IPropsStepQRCode> = ({
  showSkip,
  skipScan,
  pid,
  skipToResult,
  setRecommendedSize,
  header
}) => {
  const isMobile = useMediaQuery({ maxWidth: 1000 });

  const [WebAppURL, setWebAppURL] = useState('');
  const [storedUid, setStoredUid] = useState(
    localStorage.getItem('uid') || undefined
  );
  const [storedMid, setStoredMid] = useState(
    localStorage.getItem('mid') || undefined
  );
  const [checkCount, setCheckCount] = useState(1);

  const user = getLocalAndParse('user');

  useEffect(() => {
    setTimeout(() => {
      const storedMidRes = localStorage.getItem('mid') || '';
      const storedUidRes = localStorage.getItem('uid') || '';
      setStoredUid(storedUidRes);
      setStoredMid(storedMidRes);

      setWebAppURL(
        `${WEB_APP_URL}?isMobile=${isMobile}&uid=${storedUidRes}&mid=${storedMidRes}&product_id=${pid}&gender=${
          user?.gender ?? 'male'
        }&age=${user?.age ?? 40}&height=${user?.height ?? 170}&weight=${
          user?.weight ?? 70
        }`
      );
    }, 500);
  }, [storedUid, storedMid]);

  useEffect(() => {
    if (checkCount > 0) {
      const res = scanCheckMeasurements().then((value: boolean) => {
        if (value) {
          setCheckCount(0);
          const urlParams = new URLSearchParams(window.location.search);
          const pid = urlParams.get('product_id');
          const reco = recommend(pid).then((result: any) => {
            if (result) {
              setRecommendedSize(result);
              skipToResult();
            }
          });
        } else {
          setTimeout(() => {
            setCheckCount((prev) => prev + 1);
          }, 1000);
        }
      });
    }
  }, [checkCount]);

  const skipScanAndCorrectMid = async () => {
    try {
      const newMid = await newMeasure('question');
      setStoredMid(newMid);
    } catch (e) {
      console.log('Error: createNewMeasure -', e);
    }

    if (skipScan) skipScan();
  };

  return (
    <>
      {isMobile ? (
        <QrcodeMobile
          showSkip={showSkip}
          skipScan={skipScanAndCorrectMid}
          gender={user?.gender}
          loading={!WebAppURL}
          url={WebAppURL}
          header={header}
        />
      ) : (
        <QrcodeDesktop
          showSkip={showSkip}
          skipScan={skipScanAndCorrectMid}
          uid={storedUid}
          gender={user?.gender}
          loading={!WebAppURL}
          url={WebAppURL}
          header={header}
        />
      )}
    </>
  );
};

export default StepQRCode;
