import React from 'react';
import './header.scss';
import { Box, IconButton } from '@mui/material';
import ArrowBackIcon from '../../../icons/ArrowBackIcon';
import CloseIcon from '../../../icons/CloseIcon';

interface Props {
  hideArrowBack?: boolean;
  handleGoBack(): void;
  handleClose(value1: boolean, value2: boolean): void;
}

const ModalHeader: React.FC<Props> = ({
  hideArrowBack,
  handleClose,
  handleGoBack
}: Props) => {
  return (
    <div className="modal-header">
      <Box
        className="arrow-back-icon"
        style={{ visibility: hideArrowBack ? 'hidden' : 'visible' }}
      >
        <IconButton onClick={handleGoBack}>
          <ArrowBackIcon
            sx={{ width: '15px', height: 'auto', color: '#000' }}
          />
        </IconButton>
      </Box>
      <Box>
        <IconButton onClick={() => handleClose(false, false)}>
          <CloseIcon sx={{ color: '#000', width: '11px', height: 'auto' }} />
        </IconButton>
      </Box>
    </div>
  );
};

export default ModalHeader;
