import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import chest_1_male from '../../../assets/questionnaire/chest/chest-1-male.svg';
import chest_2_male from '../../../assets/questionnaire/chest/chest-2-male.svg';
import chest_3_male from '../../../assets/questionnaire/chest/chest-3-male.svg';

import './stepTorso.scss';
import { ImageChoiceData } from '../../../Types/ImageChoice';
import ImageChoiceWrapper from '../../ImageChoiceWrapper';

interface IPropsStepTorso {
  nextStep: () => void;
  skip: () => void;
  value: {
    current: number;
  };
  header: React.ReactNode;
  gender: string;
  isShowScanButton: boolean;
}

const StepTorso: FC<IPropsStepTorso> = ({
  nextStep,
  skip,
  value,
  header,
  gender,
  isShowScanButton
}) => {
  const { t } = useTranslation('components/torso');
  const [selected, setSelected] = useState<number | undefined>(value.current);

  const data: ImageChoiceData = [
    {
      image: chest_1_male,
      isSelected: selected === 1,
      onClick: () => {
        value.current = 1;
        setSelected(1);
      },
      text: t('size.one')
    },
    {
      image: chest_2_male,
      isSelected: selected === 2,
      onClick: () => {
        value.current = 2;
        setSelected(2);
      },
      text: t('size.two')
    },
    {
      image: chest_3_male,
      isSelected: selected === 3,
      onClick: () => {
        value.current = 3;
        setSelected(3);
      },
      text: t('size.three')
    }
  ];

  return (
    <div className="torso">
      {header}
      <ImageChoiceWrapper
        data={data}
        description={t('description')}
        nextStep={nextStep}
        selected={selected}
        skip={skip}
        subtitle={t('subtitle')}
        title={t('title')}
        gender={gender}
        currentStep={3}
        isShowScanButton={isShowScanButton}
      />
    </div>
  );
};

export default StepTorso;
