import Lottie from 'lottie-react';
import React, { useState } from 'react';
import './index.scss';

interface Props {
  isSelected: boolean;
  onClick(): void;
  image: string;
  text: string;
}

const ChoiceItem: React.FC<Props> = ({
  image,
  isSelected,
  onClick,
  text
}: Props) => {
  const [loading, setLoading] = useState(true);
  return (
    <button
      className={`belly_choice ${
        isSelected ? 'belly_choice--selected' : undefined
      }`}
      onClick={onClick}
    >
      {loading === true ? (
        <Lottie
          className="belly_choice__loading"
          animationData={require('../../../assets/animations/loading_image.json')}
        />
      ) : null}
      <img
        className={`belly_choice__image ${
          loading ? 'belly_choice__image--loading' : undefined
        }`}
        src={image}
        onLoad={(v) => {
          console.log('v', image, v);
          setLoading(false);
        }}
      />
      <p className="belly_choice__text">{text}</p>
    </button>
  );
};

export default ChoiceItem;
