import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const CloseIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0.5 0.5L11.0769 11.5" stroke="black" strokeLinecap="round" />
    <path d="M11.5 0.5L0.923076 11.5" stroke="black" strokeLinecap="round" />
  </SvgIcon>
);

export default CloseIcon;
